/**
 * Get Function
 * @param URL
 */
const get = async (url) => {
    try {
      const response = await fetch(url,{
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const json = await response.json();
      return {
        error: false,
        message: json,
      };
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  };
  const getHeader = async (url, token) => {
    try {
      const response = await fetch(url, {
        headers: {
          apiKey: `${token}`,
        },
      });
      const json = await response.json();
      return {
        error: false,
        message: json,
      };
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  };
  /**
   * Post Funcion
   * @param URL
   * @param body
   */
  const post = async (url, body) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify(body),
      });
      const json = await response.json();
      return {
        error: false,
        message: json,
      };
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  };
  const postHeader = async (url, token, body) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          apiKey: `${token}`,
        },
        body: JSON.stringify(body),
      });
      // console.log(body)
      const json = await response.json();
  
      return {
        error: false,
        message: json,
      };
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  };
  /**
   * Post Form Data Funcion
   * @param URL
   * @param body
   */
  const postFormData = async (url, token, file) => {
    var myHeaders = new Headers();
    myHeaders.append("apiKey", token);
    // console.log("I am file", file);
  
    const data = new FormData();
    file.map((item,index)=>{
      const uri = item.uri.split("file://").join("file:///");
      // console.log(uri);
      data.append(`file${index}`, {
        uri: uri,
        type: item.types, // img.type,
        name: uri.split("/").pop(),
      });
    })
  
    // console.log(data);
  
    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: data,
    //   redirect: "follow",
    // };
  
    // fetch(
    //   url,
    //   requestOptions
    // )
    //   .then((response) =>{
    //     console.log('I AM HERE',response.json())})
    //   .then((result) => console.log('i am result',result))
    //   .catch((error) => console.log("error", error));
    // return false;
    const response = await fetch(url, {
      method: "POST",
      myHeaders,
      body:data,
    });
    // console.log("before response");
    // console.log(response.text());
    // return false;
    try {
      const json = await response.json();
      return {
        error: false,
        message: json,
      };
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  };
  
  export { get, post, postFormData, postHeader, getHeader };
  