import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { get } from "../api/api";
import { getTeam } from "../api/url";
import Footer from "../Components/GlobalComponent/Footer";
import GeneralTitle from "../Components/GlobalComponent/GeneralTitle";
import Header from "../Components/GlobalComponent/Header";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import { scrollToTop } from "../Library/functions";
import { placeholder, productImage } from "../Library/icons";
import { MENUOPENED } from "../Redux/actionTypes";
import $ from "jquery";
import parse from "html-react-parser";

const Ourteam = () => {
  const [loaded, setloaded] = useState(false);
  const [data, setData] = useState({});
  const imageUrl = useSelector((state) => state.imageUrl);
  const state = useLocation();
  const dispatch = useDispatch();
  const Team = async () => {
    setloaded(false);
    const info = await get(getTeam()).then((resp) => {
      if (resp.error) {
      } else {
        setData(resp.message.data);
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    $(".navbarlink").removeClass("active");
    $(".navbarlinks2").removeClass("active");
    $('.navbarlinks3').removeClass('active')
    // $("#aboutMenu").addClass("active");
    $("#ourTeam").addClass("active");
    dispatch({ type: MENUOPENED, value: false });

    scrollToTop();
    Team();
    document.getElementById("root").classList.add("loaded");
  }, []);
  if (loaded) {
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content={data.pages.slider_teams.seoDescrition}
          />
          <meta name="keywords" content={data.pages.slider_teams.seoKeywords} />

          <meta
            property="og:title"
            content={data.pages.slider_teams.seoTitle}
          />
          <meta
            property="og:url"
            content={"genesysimpact-FE/build" + state.pathname}
          />
          <meta
            property="og:description"
            content={data.pages.slider_teams.seoDescrition}
          />
          <meta
            property="og:image"
            content={imageUrl + data.pages.slider_teams.image}
          />
          <meta property="og:type" content="Capabilities" />
          <meta
            property="og:site_name"
            content={data.pages.slider_teams.seoTitle}
          />
          <title>{data.pages.slider_teams.seoTitle}</title>
        </Helmet>
        {/* <Navbar /> */}
        <div
          className="imagecontainer3"
          style={{
            backgroundImage: `url(${imageUrl + data.pages.slider_teams.image})`,
          }}
        >
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${
                imageUrl + data.pages.slider_teams.image
              })`,
            }}
          ></div>
          <Header
            leftBorder={true}
            rightBorder={false}
            topBorder={false}
            bottomBorder={false}
            buttonDisplay={false}
            rules={[]}
            firstTitle=""
            secondTitle={data.pages.slider_teams.title}
            description={
              data.pages.slider_teams.shortDescription == null ||
              data.pages.slider_teams.shortDescription == ""
                ? ""
                : parse(data.pages.slider_teams.shortDescription)
            }
          />
        </div>
        <div className="my-5 ">
          <div class="HeaderDescription container color-grey defaultFont reveal active">
          {data.pages.slider_teams.content==''||data.pages.slider_teams.content==null?'':parse(data.pages.slider_teams.content)  }
          </div>
        </div>
        <div className="my-5 ">
          <div className="d-flex justify-content-start mt-3 ms-5 generalTitle reveal">
            <GeneralTitle Title="Leadership" />
          </div>
          <div className="container">
            <div className="row">
              {data.leaders.map((item, index) => {
                return (
                  <div className="col-12 col-sm-6 col-lg-4 p-2 reveal" key={index}>
                    <img src={imageUrl + item.image} className="w-100" />
                    <div className="d-flex justify-content-center py-2 leaderTitle">
                      {item.title}
                    </div>
                    <div className="d-flex justify-content-center py-2 leaderDescription">
                      {item.shortDescription}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="my-5">
          <div className="d-flex justify-content-start mt-3 ms-5 generalTitle reveal">
            <GeneralTitle Title="Our Team" />
          </div>
          <div className="container">
            <div className="row">
              {data.teams.map((item, index) => {
                return (
                  <div
                    className="col-12 col-sm-6 col-lg-4 p-2 d-flex flex-column reveal"
                    key={index}
                  >
                    <img src={imageUrl + item.image} className="w-100" />
                    <div className="d-flex justify-content-center py-2 leaderTitle">
                      {item.title}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Ourteam;
