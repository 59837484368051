import React from "react";
import { colors } from "../../Library/colors";
import { pc, placeholder } from "../../Library/icons";
import { fontFamilyRegular } from "../../Library/styles";
import NewsTitle from "../GlobalComponent/NewsTitle";
import "../../assets/css/News.css";
import "../../assets/css/Global.css";
import "../../assets/css/colors.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const EachProjects = ({
  title,
  id,
  items
}) => { 
  const navigate = useNavigate();
  const imageUrl = useSelector((state) => state.imageUrl);
  const length = 100;
  if(!items){
    items = []
  }
  return (
  
    <>
      <div className="d-flex justify-content-center">
      {items.length <= 0 ? "" :
        <NewsTitle title={title} />}
      </div>
      <div className="container">
          <div className="row">
            {items.map((item, index) => (
              <div className="col-12 col-md-6 col-lg-3 p-2 reveal">
                <Link to={"/singleProject/" + item.id + "/" + item.slug}>
                  <div
                    className=" d-flex flex-column p-3 background-whitegrey justify-content-between "
                    style={{ minHeight: 200 }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: 200,
                      }}
                      src={imageUrl + item.image}
                    />
                    <div className="itemTitle my-2 color-grey defaultFontBold news-title" style={{minHeight: 100}}>
                      {item.title}
                    </div>
                    <div className="itemDescription color-grey defaultFont news-description" style={{minHeight: 100}}>
                      {item.shortDescription.length > length ? 
                      item.shortDescription.substring(0, length) + '...' :
                      item.shortDescription}
                    </div>
                    <div className=" my-2">
                      <div className="d-flex align-items-center newsButton ">
                        <div className="itemButton color-grey defaultFont">
                          {item.buttonTitle}
                        </div>

                        <div className="ms-2 linkIcon ">
                          <i className="fa fa-light fa-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>

      </div>
    </>
  );
};

export default EachProjects;
