import "../../assets/css/Slider.css";
import placeholder from '../../assets/icons/gradient.jpeg';
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Parallax, Pagination, Navigation, Autoplay } from "swiper";
import { useSelector } from "react-redux";
import Loading from "../GlobalComponent/Loading";
import $ from "jquery";


const Slider = ({ slider }) => {
  const imageUrl = useSelector((state) => state.imageUrl);
  // console.log(slider, "Slider");
  const [hover, sethover] = useState(false);
  const [loaded, setloaded] = useState(false);
  const [left, setleft] = useState("60%");

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setleft("45%");
      
    }
  }, []);
  return (
    <>
      <Swiper
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        navigation={true}

        modules={[ Autoplay,Pagination, Navigation]}
        className="mySwiper"

        onAfterInit={(resp) => {
          setTimeout(() => {
            $(".mySwiper .swiper-slide-active .sliderImage .mobileHover").addClass("active");
          },1000)
        }}
        onSlideChangeTransitionEnd={(resp) => {
          $(".mySwiper .sliderImage .mobileHover").removeClass("active");
          setTimeout(() => {
            $(".mySwiper .swiper-slide-active .sliderImage .mobileHover").addClass("active");
          },1000)
        }}
        
      >
        {slider.map((item, index) => {
          return (
            <SwiperSlide
              onLoad={() => {
                document.getElementById("root").classList.add("loaded");
              }}
              key={index}
            >
              <div
                className="  sliderImage2"
                style={{ display: loaded ? "none" : "flex" }}
              >
                <img src={placeholder} />
              </div>
              <div style={{ display: loaded ? "block" : "none" }}>
                <div className="sliderImage" style={{ backgroundImage: `url(${item.image})` }}>
                  <div className="mobileHover" style={{ backgroundImage: `url(${item.additionalImage})` }}></div>
                  <img
                    src={item.image}
                    onLoad={() => {
                      setloaded(true);
                    }}
                  />
                  <img class="sliderImgHover" src={item.additionalImage} />
                </div>

                <div
                  className="swiper-slide-content2"
                  style={{
                    left: item.btnPosition==1 ?"10%"  : left,
                    top: item.btnPosition==1?"22%"  : "40%",
                  }}
                >
                  <h2 className="sliderTitle">

                    {item.title}
                  </h2>
                  {item.buttonTitle == null ||
                  item.url == null ||
                  item.buttonTitle == "" ||
                  item.url == "" ? (
                    <></>
                  ) : (
                    <div
                      className="text onhoverslider "
                      data-swiper-parallax="-100"
                    >
     
                      <a href={item.url} target='_blank' className="buttonslider">
                      <i className="fa fa-light fa-arrow-right me-2"></i>
                        {item.buttonTitle}</a>
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default Slider;
