import React, { useEffect, useState } from "react";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import NewsTitle from "../Components/GlobalComponent/NewsTitle";
import EachNews from "../Components/News/EachNews";
import "../assets/css/Work.css";
import { productImage } from "../Library/icons";
import { useLocation } from "react-router-dom";
import { scrollToTop } from "../Library/functions";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getJobs } from "../api/url";
import { get } from "../api/api";
import { JOBSPAGE, MENUOPENED } from "../Redux/actionTypes";
import { Helmet } from "react-helmet";
import Header from "../Components/GlobalComponent/Header";
import $ from "jquery";
import parse from "html-react-parser";

const Work = () => {
  const state = useLocation();

  const [loaded, setloaded] = useState(false);
  const dispatch = useDispatch();
  const jobsPage = useSelector((state) => state.jobsPage);
  const imageUrl = useSelector((state) => state.imageUrl);
  const [workImage,setworkImage]=useState('')
  const getJobsInfo = async () => {
    const news = await get(getJobs()).then((resp) => {
      // console.log(resp.message.data);
      if (resp.error) {
      } else {
        setworkImage(imageUrl+ resp.message.data.pages.slider_jobs.image)
        dispatch({ type: JOBSPAGE, value: resp.message.data });
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    $(".navbarlink").removeClass("active");
    $(".navbarlinks2").removeClass("active");
    $('.navbarlinks3').removeClass('active')
    $('#careers').addClass('active')
    dispatch({ type: MENUOPENED, value: false });
    scrollToTop();
    getJobsInfo();
    document.getElementById("root").classList.add("loaded");
  }, []);
  if (loaded) {
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content={jobsPage.pages.slider_jobs.seoDescrition}
          />
          <meta
            name="keywords"
            content={jobsPage.pages.slider_jobs.seoKeywords}
          />

          <meta
            property="og:title"
            content={jobsPage.pages.slider_jobs.seoTitle}
          />
          <meta
            property="og:url"
            content={"genesysimpact-FE/build" + state.pathname}
          />
          <meta
            property="og:description"
            content={jobsPage.pages.slider_jobs.seoDescrition}
          />
          <meta
            property="og:image"
            content={imageUrl + jobsPage.pages.slider_jobs.image}
          />
          <meta property="og:type" content="Capabilities" />
          <meta
            property="og:site_name"
            content={jobsPage.pages.slider_jobs.seoTitle}
          />
          <title>{jobsPage.pages.slider_jobs.seoTitle}</title>
        </Helmet>
        {/* <Navbar /> */}
        <div className="imageHeaderContainer">
          <div
            className="imageContainer5"
            style={{
              backgroundImage: `url(${
                imageUrl + jobsPage.pages.slider_jobs.image
              })`,
            }}
          ></div>
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${
                imageUrl + jobsPage.pages.slider_jobs.image
              })`,
            }}
          ></div>
          <div className="position-absolute" style={{ bottom: 60 }}>
            <Header
              leftBorder={false}
              rightBorder={false}
              topBorder={true}
              bottomBorder={false}
              rules={[]}
              firstTitle=""
              secondTitle={jobsPage.pages.slider_jobs.title}
              description={
                jobsPage.pages.slider_jobs.shortDescription == "" ||
                jobsPage.pages.slider_jobs.shortDescription == null
                  ? ""
                  : parse(jobsPage.pages.slider_jobs.shortDescription)
              }
            />
          </div>
        </div>
        <div class="HeaderDescription container color-grey defaultFont reveal active">
        {jobsPage.pages.slider_jobs.content==''||jobsPage.pages.slider_jobs.content==null?'':parse(jobsPage.pages.slider_jobs.content)  }
        </div>
        <div className=" d-flex justify-content-center">
          <NewsTitle title="Open Positions" />
        </div>
        <div className="mb-5">
          <div className="container">
            <div className="row">
              {jobsPage.jobs.map((item, index) => (
                <EachNews
                  event={false}
                  title={item.title}
                  description={item.shortDescription}
                  buttonTitle={item.buttonTitle}
                  imageDisplay={false}
                  url={item.url}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Work;
