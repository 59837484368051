import React from "react";
import "../../assets/css/Markets.css";
import { placeholder2, productImage } from "../../Library/icons";
import { colors } from "../../Library/colors";
import { fontFamilyRegular } from "../../Library/styles";
import HeaderDescription from "./HeaderDescription";
import { useSelector } from "react-redux";
import parse from 'html-react-parser';

const PageHeader = ({ bottomMargin, title,content, description,image,classname }) => {
  const imageUrl=useSelector(state =>state.imageUrl)
  return (
    <div className="pageHeader">
      <div className="imageContainer2" style={{ backgroundImage: `url(${imageUrl+image})` }}>
      <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${imageUrl+image})`,
            }}
          ></div>
        {/* <img src={productImage} className="w-100 h-100" /> */}
        <div className="pageheadermargin">
        <HeaderDescription
        leftBorder={true}
          firstTitle={title}
          buttonDisplay={false}
          secondTitle={description}
          description={content ==null||content==''?'':parse(content)}
          rulesDisplay={false}
          rules={[]}
        />          
        </div>

      </div>
    </div>
  );
};

export default PageHeader;
