import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "../../assets/css/OurStory.css";
import "../../assets/css/Global.css";
import { jarallax, jarallaxVideo } from "jarallax";
import { Link } from "react-router-dom";

const OurStory = ({ ourStory }) => {
  // console.log(ourStory)
  const imageUrl = useSelector((state) => state.imageUrl);

  useEffect(() => {
    jarallaxVideo();
    jarallax(document.querySelectorAll(".jarallax"));
  });
  return (
    <>
      <div className="our-story-section d-block d-sm-none ">
        <div
          className="d-flex align-items-end px-1 storyImage"
          data-jarallax
          data-jarallax-video= { ourStory.video ? 'mp4:'+ourStory.video : null }
          style= { ourStory.image ? { backgroundImage:`url(${ourStory.image})`} :   null }
        >
          <h2 >{ourStory.title}</h2>
        </div>
        <div className="p-lg-3 p-sm-3 px-3 mb-0 pb-0 pt-3 defaultFont ourStoryDesc">
          {ourStory.shortDescription}
          <br />
          <Link to={ourStory.url}>
          <div className="meetTheTeam  d-inline-block defaultFont ">
            {ourStory.buttonTitle}
            <div className="ms-2 d-inline-block btn-arrow">
              <i className="fa fa-light fa-arrow-right"></i>
            </div>
          </div>
          </Link>
        </div>
      </div>
      <div className="our-story-section d-none d-sm-block reveal">
        <div
          className="parallax jarallax pb-5 pt-5 p-lg-5 storyImage" 
          data-jarallax
          data-jarallax-video= { ourStory.video ? 'mp4:'+ourStory.video : null }
          style= { ourStory.image ? { backgroundImage:`url(${ourStory.image})`} :   null }
        
        >
          <h2 >{ourStory.title}</h2>
          <div className="p-lg-3 p-sm-3 px-3 mb-0 pb-0 pt-3  ourStoryDesc">
            {ourStory.shortDescription}
            <br />
            <Link to={ourStory.url}>

            <div className="meetTheTeam px-3 d-inline-block defaultFont pb-0 p-3 pt-0">
              {ourStory.buttonTitle}
              <div className="ms-2 d-inline-block btn-arrow">
                <i className="fa fa-light fa-arrow-right"></i>
              </div>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurStory;
