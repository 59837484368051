import React, { useEffect, useState } from "react";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import NewsTitle from "../Components/GlobalComponent/NewsTitle";
import "../assets/css/SingleProject.css";
import { colors } from "../Library/colors";
import { city, productImage } from "../Library/icons";
import { fontFamilyRegular } from "../Library/styles";
import "../assets/css/colors.css";
import "../assets/css/Global.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { scrollToTop } from "../Library/functions";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import { get } from "../api/api";
import { getSingleProject } from "../api/url";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import Header from "../Components/GlobalComponent/Header";
import parse, { domToReact } from "html-react-parser";
import { MENUOPENED } from "../Redux/actionTypes";
import $ from "jquery";
const SingleProject = () => {
  const params = useParams();
  const state = useLocation();
  const [loaded, setloaded] = useState(false);
  const [data, setdata] = useState({});
  const imageUrl = useSelector((state) => state.imageUrl);
  const dispatch = useDispatch();
  const getSingleProjectInfo = async () => {
    setloaded(false);
    const info = await get(getSingleProject(params.id)).then((resp) => {
      if (resp.error) {
      } else {
        // console.log(resp.message.data);
        setdata(resp.message.data);
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    $(".navbarlink").removeClass("active");
    $(".navbarlinks2").removeClass("active");
    $('.navbarlinks3').removeClass('active')
    dispatch({ type: MENUOPENED, value: false });
    scrollToTop();
    getSingleProjectInfo();
    document.getElementById("root").classList.add("loaded");
  }, [params.id]);
  if (loaded) {
    return (
      <>
        <Helmet>
          <meta name="description" content={data.project.content} />
          <meta name="keywords" content={data.project.content} />

          <meta property="og:title" content={data.project.title} />
          <meta
            property="og:url"
            content={"genesysimpact-FE/build" + state.pathname}
          />
          <meta property="og:description" content={data.project.content} />
          <meta property="og:image" content={imageUrl + data.project.image} />
          <meta property="og:type" content="Projects" />
          <meta property="og:site_name" content={data.project.title} />
          <title>{data.project.seoTitle ? data.project.seoTitle : data.project.title}</title>
        </Helmet>
        {/* <Navbar /> */}
        <div
          className="imageContainer"
          style={{ backgroundImage: `url(${imageUrl + data.project.image})` }}
        >
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${imageUrl + data.project.image})`,
            }}
          ></div>
          <HeaderDescription
            leftBorder={false}
            rightBorder={true}
            topBorder={false}
            bottomBorder={false}
            buttonDisplay={false}
            rules={[]}
            firstTitle=""
            secondTitle={data.project.title}
            description={
              data.project.content == null || data.project.content == ""
                ? ""
                : parse(data.project.content)
            }
          />
        </div>
        <div className="d-flex justify-content-center">
          <NewsTitle title={data.project.title} />
        </div>
        <div className="HeaderDescription container color-grey defaultFont reveal">
          {data.project.secondContent.length == 0 ||
          data.project.secondContent == null
            ? ""
            : parse(data.project.secondContent)}
        </div>

        {data.relatedProjects.map((item, index) => (
          <div className="container my-5 p-0">
            <div
              className="EachItem2 d-flex justify-content-center my-5  justify-content-md-end align-items-end"
              style={{ backgroundImage: `url(${imageUrl + item.image})` }}
            >
              <div
                className="phoneImage"
                style={{
                  backgroundImage: `url(${imageUrl + item.image})`,
                }}
              ></div>
              <div className="p-0 reveal" style={{ zIndex: 2 }}>
                <Link to={"/singleProject/" + item.id + "/" + item.title}>
                  <Header
                    leftBorder={true}
                    rightBorder={false}
                    topBorder={false}
                    rules={[]}
                    bottomBorder={false}
                    buttonDisplay={true}
                    buttonTitle="Learn More"
                    firstTitle={item.title}
                    secondTitle={item.title}
                    description={item.shortDescription}
                  />
                </Link>
              </div>
              <div className="backgroundContainer background-lightblue"></div>
            </div>
          </div>
        ))}
        {/* <div className="container my-5 p-0">
          <div
            className="EachItem2 d-flex justify-content-center  justify-content-md-end align-items-end"
            style={{ backgroundImage: `url(${city})` }}
          >
            <div
              className="phoneImage"
              style={{
                backgroundImage: `url(${city})`,
              }}
            ></div>
            <div className="p-0" style={{ zIndex: 2 }}>
              <HeaderDescription
                leftBorder={true}
                rightBorder={false}
                topBorder={false}
                rules={[]}
                bottomBorder={false}
                buttonDisplay={true}
                buttonTitle="Learn More"
                firstTitle="Project 3"
                secondTitle="On the frontlines of 
                  health innovation.
                "
                description="We lead the technology, systems and research that drive better health. Because health agency missions have real life outcomes – providing access to care, advancing medical research and enhancing lives.
  
                From deploying one of the largest clouds in the federal government, to detecting fraud, waste and abuse to protect the Medicare trust fund, researching new breakthroughs in treating traumatic brain injuries and connecting veterans with benefits they have earned."
              />
            </div>
            <div className="backgroundContainer background-lightblue"></div>
          </div>
        </div> */}
        <div className="mt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default SingleProject;
