import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../App.css";
import Footer from "../Components/GlobalComponent/Footer";
import Navbar from "../Components/GlobalComponent/Navbar";
import Slider from "../Components/HomePageComponent/Slider";
import Links from "../Components/HomePageComponent/Links";
import NewsEvents from "../Components/HomePageComponent/NewsEvents";
import OurImpact from "../Components/HomePageComponent/OurImpact";
import OurStory from "../Components/HomePageComponent/OurStory";
import Markets from "../Components/HomePageComponent/Markets";
import { get } from "../api/api";
import { getHomePage } from "../api/url";
import { HOMEPAGE } from "../Redux/actionTypes";
import Loading from "../Components/GlobalComponent/Loading";
import { Helmet } from "react-helmet";
import $ from 'jquery'
const HomePage = () => {
  const dispatch = useDispatch();
  const homePage = useSelector((state) => state.homePage);
  const [loaded, setloaded] = useState(false);
  const getHomePageInfo = async () => {
    // setloaded(true)
    const home = await get(getHomePage()).then((resp) => {
      // console.log(resp);
      if (resp.error) {
      } else {
        dispatch({ type: HOMEPAGE, value: resp.message.data });
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    $('.navbarlink').removeClass('active')
    $('.navbarlinks2').removeClass('active')
    $('.navbarlinks3').removeClass('active')
    getHomePageInfo();
  }, []);
  if (loaded) {
    return (
      <div>
        <Helmet>
        <meta property="og:title" content="Genesys Impact - Home" />
          {/* <meta
            property="og:url"
            content={"genesysimpact-FE/build" + params.pathname}
          /> */}
          <meta property="og:description" content="Home" />
          {/* <meta property="og:image" content={imageUrl + aboutPage.pages.slider_about.image} /> */}
          <meta property="og:type" content="Genesys Impact - Home" />
          <meta property="og:site_name" content="Genesys Impact - Home" />
          <title>Genesys Impact - Home</title>
        </Helmet>
        {/* <Navbar /> */}

        <Slider slider={homePage.sliders} />
        <OurImpact impacts={homePage.projects} />
        <OurStory ourStory={homePage.pages.homepage_story} />
        <Markets markets={homePage.markets} />
        <NewsEvents news={homePage.news} />
        <Links pages={homePage.pages} />

        <Footer />
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default HomePage;
