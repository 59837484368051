import React, { useEffect, useState } from "react";
import OtherProjects from "../Components/Projects/OtherProjects";
import UpcomingEvents from "../Components/News/UpcomingEvents";
import Footer from "../Components/GlobalComponent/Footer";
import "../assets/css/News.css";
import { useLocation } from "react-router-dom";
import { scrollToTop } from "../Library/functions";
import Loading from "../Components/GlobalComponent/Loading";
import { get } from "../api/api";
import { getNews, getProjects } from "../api/url";
import { useDispatch, useSelector } from "react-redux";
import { MENUOPENED, PROJECTSPAGE } from "../Redux/actionTypes";
import { Helmet } from "react-helmet";
import Header from "../Components/GlobalComponent/Header";
import $ from "jquery";
import parse from "html-react-parser";

const Projects = () => {
  const state = useLocation();
  const [loaded, setloaded] = useState(false);
  const dispatch = useDispatch();
  const projectsPage = useSelector((state) => state.projectsPage);
  const imageUrl = useSelector((state) => state.imageUrl);
  const getProjectsInfo = async () => {
    const projects = await get(getProjects()).then((resp) => {
        
      // console.log(resp);
      if (resp.error) {
      } else {
        dispatch({ type: PROJECTSPAGE, value: resp.message.data });
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    $(".navbarlink").removeClass("active");
    $(".navbarlinks2").removeClass("active");
    $('.navbarlinks3').removeClass('active')
    dispatch({ type: MENUOPENED, value: false });
    scrollToTop();
    getProjectsInfo();
    document.getElementById("root").classList.add("loaded");
  }, []);
  if (loaded) {
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content={projectsPage.pages.slider_list_projects.seoDescrition}
          />
          <meta
            name="keywords"
            content={projectsPage.pages.slider_list_projects.seoKeywords}
          />

          <meta
            property="og:title"
            content={projectsPage.pages.slider_list_projects.seoTitle}
          />
          <meta
            property="og:url"
            content={"genesysimpact-FE/build" + state.pathname}
          />
          <meta
            property="og:description"
            content={projectsPage.pages.slider_list_projects.seoDescrition}
          />
          <meta
            property="og:image"
            content={imageUrl + projectsPage.pages.slider_list_projects.image}
          />
          <meta property="og:type" content="Capabilities" />
          <meta
            property="og:site_name"
            content={projectsPage.pages.slider_list_projects.seoTitle}
          />
          <title>Projects</title>
        </Helmet>

        {/* <Navbar /> */}
        <div
          className="imageContainer4"
          style={{
            backgroundImage: `url(${
              imageUrl + projectsPage.pages.slider_list_projects.image
            })`,
          }}
        >
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${
                imageUrl + projectsPage.pages.slider_list_projects.image
              })`,
            }}
          ></div>
          <Header
            leftBorder={true}
            rightBorder={false}
            topBorder={false}
            bottomBorder={false}
            rules={[]}
            firstTitle=""
            secondTitle={projectsPage.pages.slider_list_projects.title}
            description={
              projectsPage.pages.slider_list_projects.content == "" ||
              projectsPage.pages.slider_list_projects.content == null
                ? ""
                : parse(projectsPage.pages.slider_list_projects.content)
            }
          />
        </div>
        <OtherProjects projects={projectsPage} />
        <div className="pt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Projects;
