import React, { useEffect, useState } from "react";
import "../../assets/css/Navbar.css";
import { colors } from "../../Library/colors";
import { navimage, Logo, rectangle } from "../../Library/icons";
import "../../assets/css/colors.css";
import "../../assets/css/Global.css";
import { Link, useNavigate } from "react-router-dom";
import { get } from "../../api/api";
import { getFooter, getPageInfo } from "../../api/url";
import { useDispatch, useSelector } from "react-redux";
import {
  CAPABILITIES,
  CONTACTINFO,
  MENUOPENED,
  FOOTERITEMS,
  MARKETS,
} from "../../Redux/actionTypes";
import logo from "../../assets/icons/websiteLogo.png";

import $ from "jquery";
const Navbar = () => {

  let ulList = document.querySelector('.mega-box');
  function setVisibility(){
    ulList.addClass('mega-visibility');
  }

  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  // const [markets, setmarkets] = useState([]);
  // const [capabilities, setcapabilities] = useState([]);
  const markets = useSelector((state) => state.markets);
  const capabilities = useSelector((state) => state.capabilities);
  const [menuImage, setmenuImage] = useState("");
  const [menuAboutImage, setmenuAboutImage] = useState("");
  const [menuCapabilitiesImage, setmenuCapabilitiesImage] = useState("");
  const [menuImpactImage, setmenuImpactImage] = useState("");

  const [keyWord, setkeyWord] = useState("");
  const menuOpened = useSelector((state) => state.menuOpened);
  const dispatch = useDispatch();
  const navbarItems = useSelector((state) => state.navbarItems);
  const imageUrl = useSelector((state) => state.imageUrl);

  const navigateTo = (Path, word) => {
    navigate(Path, {
      state: {
        word,
      },
    });
  };

  const getFooterInfo = async () => {
    if (markets.length == 0 || capabilities.length == 0) {
      let info = await get(getFooter()).then((resp) => {
        // console.log(resp.message.data.capabilities);
        if (resp.error) {
        } else {
          // dispatch({ type: FOOTERITEMS, value: resp.message.data });
          dispatch({ type: MARKETS, value: resp.message.data.markets });
          dispatch({
            type: CAPABILITIES,
            value: resp.message.data.capabilities,
          });
          dispatch({ type: CONTACTINFO, value: resp.message.data.contactInfo });
          // setmarkets(resp.message.data.markets);
          // setcapabilities(resp.message.data.capabilities);
        }
      });
    }
  };
  const menuImageInfo = async () => {
    const info = await get(getPageInfo("menu_page")).then((resp) => {
      if (resp.error) {
      } else {
        setmenuImage(imageUrl + resp.message.data.page.image);
      }
    });
    const info2 = await get(getPageInfo("about_menu_Image")).then((resp) => {
      if (resp.error) {
      } else {
        setmenuAboutImage(imageUrl + resp.message.data.page.image);
      }
    });
    const info3 = await get(getPageInfo("capabilities_menu_image")).then(
      (resp) => {
        if (resp.error) {
        } else {
          setmenuCapabilitiesImage(imageUrl + resp.message.data.page.image);
        }
      }
    );
    const info4 = await get(getPageInfo("ourImpactMenu")).then((resp) => {
      if (resp.error) {
      } else {
        setmenuImpactImage(imageUrl + resp.message.data.page.image);
      }
    });
  };

  useEffect(() => {
    getFooterInfo();
    menuImageInfo();
  }, []);
  return (
    <nav className="background-white ">
      <div className="wrapper align-items-center align-items-lg-start">
        <div
          className="logo"
          onClick={() => {
            navigate("/home");
          }}
        >
          <img src={logo} />
        </div>

        <div
          className="d-block d-lg-flex justify-content-between navLinkContainer "
          style={{ width: "85%" }}
        >
          <input
            type="radio"
            name="slider"
            id="menu-btn"
            checked={menuOpened}
            onChange={() => {}}
          />
          <input
            type="radio"
            name="slider"
            id="close-btn"
            checked={!menuOpened}
            onChange={() => {}}
          />

          <ul
            className="nav-links justify-content-center"
            style={{ width: "85%" }}
          >
            <li className="liItems">
              <div className="desktop-item navbarlinks hover-underline-animation">
                <div className="navbarlink" id="capabilitiesMenu">
                  CAPABILITIES
                </div>
              </div>
              <input type="checkbox" id="showMega2" onChange={() => {}} />
              <label htmlFor="showMega2" className="mobile-item">
                CAPABILITIES
              </label>
              <div className="mega-box p-0" style={{ zIndex: 2 }}>
                <div className="content p-0 p-lg-5 px-0 px-lg-3 row">
                  <div className="col-12 col-lg-5 d-flex align-items-center">
                    <div className="navbar-phone">
                      <div className=" navbar-image d-flex">
                        <div className="background-lightblue background-lightblueLine"></div>
                        <img
                          src={menuCapabilitiesImage}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-7  p-lg-auto">
                    <div className="row">
                      {capabilities.map((item, index) => (
                        <div className="col-12 col-lg-4 eachRow">
                          <div
                            className="navbarlinks2"
                            id={"capabilitiesSubMenu-" + item.category.id}
                          >
                            {item.category.title}
                          </div>
                          <div>
                            {item.entries.map((itemData, index) => (
                              <>
                                <Link
                                  to={
                                    "/capabilities/" +
                                    itemData.id +
                                    "/" +
                                    itemData.slug
                                  }
                                  onClick={setVisibility}
                                >
                                  <div
                                    onClick={() => {
                                      dispatch({
                                        type: MENUOPENED,
                                        value: false,
                                      });
                                    }}
                                  >
                                    <div
                                      className="navbarlinks3"
                                      id={"capabilitiesMenu-" + itemData.id}
                                    >
                                      {itemData.title}
                                    </div>
                                  </div>
                                </Link>
                              </>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="liItems">
              <div className="desktop-item navbarlinks hover-underline-animation  ">
                <div className="navbarlink" id="marketsMenu">
                  INDUSTRIES
                </div>
              </div>
              <input type="checkbox" id="showMega" onChange={() => {}} />
              <label htmlFor="showMega" className="mobile-item">
                INDUSTRIES
              </label>
              <div className="mega-box p-0" style={{ zIndex: 2 }} >
                <div
                  // for="close-btn"
                  className="btn close-btn"
                  onClick={() =>
                    dispatch({ type: MENUOPENED, value: !menuOpened })
                  }
                >
                  <i className="fas fa-times"></i>
                </div>
                <div className="content p-0 p-lg-5 px-0 px-lg-3 row">
                  <div className="col-12 col-lg-5 d-flex align-items-center">
                    <div className="navbar-phone">
                      <div className=" navbar-image d-flex">
                        <div className="background-lightblue background-lightblueLine"></div>
                        <img src={menuImage} alt="" style={{ width: "100%" }} />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-7  p-lg-auto">
                    <div className="row">
                      {markets.map((item, index) => {
                        if (Array.isArray(item.category)) {
                          return (
                            <>
                              <div
                                className="navbarlinks2"
                                id={"MarketSubMenu-" + item.category.id}
                              >
                                {item.category.title}
                              </div>
                              <div className="col-12 col-lg-4  eachRow">
                                {item.entries.map((itemData, index) => (
                                  <>
                                    <Link
                                      to={
                                        "/market/" +
                                        itemData.id +
                                        "/" +
                                        itemData.slug
                                      }
                                      onClick={setVisibility}
                                    >
                                      <div
                                        onClick={() => {
                                          dispatch({
                                            type: MENUOPENED,
                                            value: false,
                                          });
                                        }}
                                      >
                                        <div
                                          className="navbarlinks3"
                                          id={"marketsMenu-" + itemData.id}
                                        >
                                          {itemData.title}
                                        </div>
                                      </div>
                                    </Link>
                                  </>
                                ))}
                              </div>
                            </>
                          );
                        } else {
                          return (
                            <div className="col-12 col-lg-4  eachRow">
                              <div
                                className="navbarlinks2"
                                id={"marketsMenu-" + item.category.id}
                              >
                                {item.category.title}
                              </div>
                              <div>
                                {item.entries.map((itemData, index) => (
                                  <>
                                    <Link
                                      to={
                                        "/market/" +
                                        itemData.id +
                                        "/" +
                                        itemData.slug
                                      }
                                      onClick={setVisibility}
                                    >
                                      <div
                                        onClick={() => {
                                          dispatch({
                                            type: MENUOPENED,
                                            value: false,
                                          });
                                        }}
                                      >
                                        <div
                                          className="navbarlinks3"
                                          id={"marketsMenu-" + itemData.id}
                                        >
                                          {itemData.title}
                                        </div>
                                      </div>
                                    </Link>
                                  </>
                                ))}
                              </div>
                            </div>
                          );
                        }
                        // <li
                        //   className="col-12 col-lg-4"
                        //   key={index}
                        //   onClick={() => {
                        //     dispatch({ type: MENUOPENED, value: false });
                        //   }}
                        // >
                        //   <Link to={"/market/" + item.id + "/" + item.title}>
                        //     <div
                        //       className="navbarlinks2"
                        //       id={"marketsMenu-" + item.id}
                        //     >
                        //       {item.title}
                        //     </div>
                        //   </Link>
                        // </li>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="liItems">
              <div className="desktop-item navbarlinks hover-underline-animation">
                <div className="navbarlink" id="ourImpact">
                  OUR IMPACT
                </div>
              </div>
              <input type="checkbox" id="showMega3" onChange={() => {}} />
              <label htmlFor="showMega3" className="mobile-item">
                OUR IMPACT
              </label>
              <div className="mega-box p-0 " style={{ zIndex: 2 }}>
                <div className="content p-0 p-lg-5 px-0 px-lg-3 row">
                  <div className="col-12 col-lg-5 d-flex align-items-center">
                    <div className="navbar-phone">
                      <div className=" navbar-image d-flex">
                        <div className="background-lightblue background-lightblueLine"></div>
                        <img
                          src={menuImpactImage}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-7 p-0 p-lg-auto">
                    <div className="xw ">
                      <div className="row">
                        <div className="col-12  p-0">
                          <ul className="mega-links ms-3">
                            <li
                              className="col-12 col-lg-3 "
                              onClick={() => {
                                dispatch({ type: MENUOPENED, value: false });
                              }}
                            >
                              <Link to={"/technology"} onClick={setVisibility}>
                                <div className="navbarlinks3" id="technology">
                                  Technology for Good
                                </div>
                              </Link>
                            </li>

                            <li
                              className="col-12 col-lg-3 "
                              onClick={() => {
                                dispatch({ type: MENUOPENED, value: false });
                              }}
                            >
                              <Link to={"/caseStudies"} onClick={setVisibility}>
                                <div className="navbarlinks3" id="caseStudies">
                                  Case Studies
                                </div>
                              </Link>
                            </li>
                            <li
                              className="col-12 col-lg-3 "
                              onClick={() => {
                                dispatch({ type: MENUOPENED, value: false });
                              }}
                            >
                              <Link to={"/partners"} onClick={setVisibility}>
                                <div className="navbarlinks3" id="partners">
                                  Partners
                                </div>
                              </Link>
                            </li>
                            <li
                              className="col-12 col-lg-3 "
                              onClick={() => {
                                dispatch({ type: MENUOPENED, value: false });
                              }}
                            >
                              <Link to={"/projects"} onClick={setVisibility}>
                                <div className="navbarlinks3" id="projects">
                                Projects
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="liItems contactLink">
              <div
                className="desktop-item navbarlinks"
                onClick={() => {
                  dispatch({ type: MENUOPENED, value: false });
                }}
              >
                <Link to={"/work"}>
                  <div className="navbarlinks">
                    <div className="navbarlink" id="careers">
                      CAREERS
                    </div>
                  </div>
                </Link>
              </div>
            </li>
            <li className="liItems">
              <div className="desktop-item navbarlinks hover-underline-animation lastOne">
                <div className="navbarlink" id="aboutMenu">
                  ABOUT US
                </div>
              </div>
              <input type="checkbox" id="showMega4" onChange={() => {}} />
              <label htmlFor="showMega4" className="mobile-item">
                ABOUT US
              </label>
              <div className="mega-box p-0 " style={{ zIndex: 2 }}>
                <div className="content p-0 p-lg-5 px-0 px-lg-3 row">
                  <div className="col-12 col-lg-5 d-flex align-items-center">
                    <div className="navbar-phone">
                      <div className=" navbar-image d-flex">
                        <div className="background-lightblue background-lightblueLine"></div>
                        <img
                          src={menuAboutImage}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-7 p-0 p-lg-auto">
                    <div className="">
                      <div className="row">
                        <div className="col-12  p-0">
                          <ul className="mega-links ms-3">
                            <li
                              className="col-12 col-lg-6 "
                              onClick={() => {
                                dispatch({ type: MENUOPENED, value: false });
                              }}
                            >
                              <Link to={"/about/" + "our-story"} onClick={setVisibility}>
                                <div className="navbarlinks3" id="ourStory">
                                  About Genesys Impact
                                </div>
                              </Link>
                            </li>

                            <li
                              className="col-12 col-lg-6 "
                              onClick={() => {
                                dispatch({ type: MENUOPENED, value: false });
                              }}
                            >
                              <Link to={"/our-strategy"} onClick={setVisibility}>
                                <div className="navbarlinks3" id="ourStrategy">
                                  Our Strategy
                                </div>
                              </Link>
                            </li>
                            <li
                              className="col-12 col-lg-6 "
                              onClick={() => {
                                dispatch({ type: MENUOPENED, value: false });
                              }}
                            >
                              <Link to={"/our-team"} onClick={setVisibility}>
                                <div className="navbarlinks3" id="ourTeam">
                                  Our Team
                                </div>
                              </Link>
                            </li>
                            <li
                              className="col-12 col-lg-6 "
                              onClick={() => {
                                dispatch({ type: MENUOPENED, value: false });
                              }}
                            >
                              <Link to={"/our-culture"} onClick={setVisibility}>
                                <div className="navbarlinks3" id="ourCulture">
                                  Our Culture
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li
              className="searchImage liItems d-flex d-lg-none"
              onClick={() => {
                dispatch({ type: MENUOPENED, value: false });
              }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              // onClick={()=>{
              //   $('.modal').addClass('show')
              //   $('.modal-backdrop').addClass('show')
              // }}
            >
              <div className="navbarlinks">
                <div className="navbarlink">SEARCH</div>
              </div>
              <i className="fa fa-light fa-magnifying-glass fs-5"></i>
            </li>
          </ul>
          <ul className="nav-links d-none d-lg-inline-flex">
            <li
              className="searchImage liItems"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <i className="fa fa-light fa-magnifying-glass fs-5"></i>
            </li>
          </ul>
          <div className="d-flex align-items-center justify-content-end">
            <label
              htmlFor="menu-btn"
              className="btn menu-btn tex"
              onClick={() => dispatch({ type: MENUOPENED, value: !menuOpened })}
            >
              <i className="fas fa-bars"></i>
            </label>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
      >
        <button
          type="button"
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            backgroundColor: "white",
            opacity: 1,
            width: 40,
            height: 40,
          }}
          className="btn-close"
          data-bs-dismiss="modal"
          id="closeButtonModal"
          aria-label="Close"
        ></button>
        <div className="modal-dialog dialogModal">
          <div className="modal-content">
            {/* <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Search
              </h5> */}

            {/* </div> */}
            <div className="modal-body body-modal">
              <form
                action="#"
                className="close formInput"
                onSubmit={(e) => {
                  $("#closeButtonModal").trigger("click");
                  e.preventDefault();
                  // $('#exampleModal').modal('close')
                  if (keyWord == "") {
                  } else {
                    navigate(`/search?keyword=${keyWord}`);
                  }
                }}
              >
                <input
                  className="searchInput px-2"
                  type="text"
                  id="myText"
                  name="keyword"
                  placeholder="Enter your search keyword here.."
                  autoComplete="off"
                  onChange={(item) => {
                    // console.log(item.target.value)
                    setkeyWord(item.target.value);
                  }}
                />
              </form>
              <button
                type="button"
                className="btn searchButton"
                data-bs-dismiss="modal"
                onClick={() => {
                  if (keyWord == "") {
                  } else {
                    navigate(`/search?keyword=${keyWord}`);
                  }
                }}
              >
                Search
              </button>
            </div>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="btn closeButton"
                data-bs-dismiss="modal"
                id="closeButtonModal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn searchButton"
                data-bs-dismiss="modal"
                onClick={() => {
                  if (keyWord == "") {
                  } else {
                    navigate(`/search?keyword=${keyWord}`);
                  }
                }}
              >
                Go Search
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
