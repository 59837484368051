import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { colors } from "../../Library/colors";
import { fontFamilyRegular } from "../../Library/styles";
import GeneralTitle from "../GlobalComponent/GeneralTitle";
import "../../assets/css/colors.css";
import "../../assets/css/Global.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import placeholder from '../../assets/icons/gradient.jpeg';
import $ from "jquery";


const Markets = ({ markets }) => {
  const imageUrl = useSelector((state) => state.imageUrl);
  const [slideItem, setslideItem] = useState(4.5);
  const [loaded, setloaded] = useState(false);

  // const handleResize = () => {
  //   if (window.innerWidth < 992 && window.innerWidth >= 576) {
  //     setslideItem(2.3);
  //   } else {
  //     if (window.innerWidth < 1400 && window.innerWidth > 992) {
  //       setslideItem(3.5);
  //     } else {
  //       if (window.innerWidth < 576) {
  //         setslideItem(1.1);
  //       } else {
  //         setslideItem(4.5);
  //       }
  //     }
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  // });
  // useEffect(() => {
  //   if (window.innerWidth < 992 && window.innerWidth >= 576) {
  //     setslideItem(2.3);
  //   } else {
  //     if (window.innerWidth < 1400 && window.innerWidth > 992) {
  //       setslideItem(3.5);
  //     } else {
  //       if (window.innerWidth < 576) {
  //         setslideItem(1.1);
  //       } else {
  //         setslideItem(4.5);
  //       }
  //     }
  //   }
  // }, []);
  return (
    <div className="d-flex flex-column my-0 my-sm-5 reveal">
      <div className="ps-3 ps-sm-5 d-flex justify-content-start mt-3 ms-3 ms-sm-5 generalTitle">
        <GeneralTitle Title={"Industries"} />
      </div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        breakpoints={{
          576: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 4.5,
            spaceBetween:0,
          },
          2500: {
            slidesPerView: 7,
            spaceBetween: 50,
          },
        }}
        // centeredSlides={true}
        // slidesPerView={slideItem}
        navigation={true}
        // loop={true}
        // pagination={true}
        modules={[Pagination, Navigation]}
        className="marketSwiper"
        onAfterInit={(resp) => {
          setTimeout(() => {
            $(".marketSwiper .swiper-slide-active .hovereffect").addClass("active");
          },100)
        }}
        onSlideChangeTransitionEnd={(resp) => {
          $(".marketSwiper .hovereffect").removeClass("active");
          setTimeout(() => {
            $(".marketSwiper .swiper-slide-active .hovereffect").addClass("active");
          },100)
        }}
      >
        {markets.map((item, index) => {
          return (
            <SwiperSlide  key={index}>
              <Link to={"/market/" + item.id + "/" + item.slug}>
                <div className="card  hovereffect p-3">
                  <div className="card-body marketItem  ">
                    <div className="imagecard">
                      {/* <img
                        src={ item.image}
                        style={{ width: "100%" }}
                      /> */}
                      <img
                        src={placeholder}
                        style={{
                          width: "100%",
                          display: loaded ? "none" : "block",
                        }}
                      />
                      <img
                        onLoad={() => {
                          setloaded(true);
                        }}
                        className='marketImage'
                        src={item.image}
                        style={{
                          width: "100%",
                          display: loaded ? "block" : "none",
                          
                        }}
                      />
                    </div>
                    <div className="descriptionhover">
                      <h4 className="cardTitle my-2 color-darkgrey ">
                        {item.title}
                      </h4>
                      <div className="cardDesctiption color-grey ">
                        {item.shortDescription}
                      </div>
                      {item.buttonTitle==null||item.buttonTitle==''?(
                        <></>
                      ):(

                      <div className="cardDesctiption color-grey defaultFont mt-3 swiperBtnLink">
                        {item.buttonTitle} <i className="fa fa-light fa-arrow-right"></i>
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <Carousel
        responsive={responsive}
        containerClass="carousel-container"
        itemClass="CarouselItem"
      >
        {markets.map((item, index) => {
          return (
            <Link to={"/market/" + item.id + "/" + item.title}>
              <div className="card  hovereffect p-3" key={index}>
                <div className="card-body  ">
                  <div className="imagecard">
                    <img
                      src={imageUrl + item.image}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div className="descriptionhover">
                    <div className="cardTitle my-2 color-darkgrey defaultFontBold ">
                      {item.title}
                    </div>
                    <div className="cardDesctiption color-grey defaultFont">
                      {item.shortDescription}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
        <div className=""></div>
      </Carousel> */}
    </div>
  );
};

export default Markets;
