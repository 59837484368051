import React, { useEffect, useState } from "react";
import "../../assets/css/Footer.css";
import "../../assets/css/colors.css";
import { fontFamilyRegular } from "../../Library/styles";
import { Logo } from "../../Library/icons";
import { Navigate, Link, useNavigate, redirect } from "react-router-dom";
import { getFooter } from "../../api/url";
import { get } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { CONTACTINFO } from "../../Redux/actionTypes";
import parse from "html-react-parser";
import $ from "jquery";
const Footer = () => {
  const navigate = useNavigate();
  const title = "hdksahkjdhska";
  const markets = useSelector((state) => state.markets);
  const capabilities = useSelector((state) => state.capabilities);
  const contactInfo = useSelector((state) => state.contactInfo);
  const date = new Date();
  // const [contacts, setcontacts] = useState([]);
  const dispatch = useDispatch();

  // const getFooterInfo = async () => {
  //   let info = await get(getFooter()).then((resp) => {
  //     if (resp.error) {
  //     } else {
  //       dispatch({ type: CONTACTINFO, value: resp.message.data.contactInfo });
  //       // setcontacts(resp.message.data.contactInfo);
  //     }
  //   });
  // };
  useEffect(() => {
    // getFooterInfo();
  }, []);
  return (
    <div className="d-flex flex-column FooterContainer background-whitegrey">
      <div className="mainContainer row d-flex justify-content-xl-around justify-content-center justify-content-md-start ">
        {/* <div className="col-12 col-sm-6 col-md-4 col-xl-2 mt-2">
          <div className="p-2 itemContainer">
            <div className="footerHeaderTitle color-darkgrey mb-1">Markets</div>
            <div className="d-flex flex-column">
              {markets.map((item, index) => {
                if (Array.isArray(item.category)) {
                  return (
                    <>
                      {item.entries.map((itemData, index) => (
                        <>
                          <Link
                            key={index}
                            to={"/market/" + itemData.id + "/" + itemData.title}
                          >
                            <div className="footerSubTitle ">
                              {itemData.title}
                            </div>
                          </Link>
                        </>
                      ))}
                    </>
                  );
                } else {
                  return (
                    <>
                      <div className="subFooterTitle  color-darkgrey">
                        {item.category.title}
                      </div>
                      <div
                        className=""
                      >
                        {item.entries.map((itemData, index) => (
                          <>
                            <Link
                              key={index}
                              to={
                                "/market/" + itemData.id + "/" + itemData.title
                              }
                            >
                              <div className="footerSubTitle SubSubTitle">
                                {itemData.title}
                              </div>
                            </Link>
                          </>
                        ))}
                      </div>
                    </>
                  );
                }
              })}
            </div>
          </div>
        </div> */}
        <div className="col-12 col-sm-6 col-md-4 col-xl-5 mt-2">
          <div className="p-2 itemContainer">
            {/* <div className="footerHeaderTitle color-darkgrey mb-1">
              Capabilities
            </div> */}
            <div className="d-flex flex-row" style={{flexWrap:'wrap'}}>
              {capabilities.map((item, index) => (
                <>
                  <div className="footerDiv">

                  <div className="subFooterTitle  color-darkgrey">
                    {item.category.title}
                  </div>
                  <div className="">
                    {item.entries.map((itemData, index) => (
                      <>
                        <Link
                          key={index}
                          to={
                            "/capabilities/" +
                            itemData.id +
                            "/" +
                            itemData.title
                          }
                        >
                          <div className="footerSubTitle">
                            {itemData.title}
                          </div>
                        </Link>
                      </>
                    ))}
                  </div>
                  </div>
                  {/* </Link> */}
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-1 mt-2">
          <div className="p-2 itemContainer">
            <div className="d-flex flex-column">
              <div className="subFooterTitle  color-darkgrey">
                Contact
              </div>
              <Link to={"/contact"}>
                <div className="footerSubTitle">Contact Us</div>
              </Link>
              <Link to={"/news"}>
                <div className="footerSubTitle">News</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-2 mt-2">
          <div className="p-2 itemContainer">
              <Link to={"/work"}>
              <div className="subFooterTitle  color-darkgrey mb-1">Careers</div>
              </Link>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-3 mt-2 p-0">
          <div className="footerLogo m-auto m-sm-0">
            <img src={Logo} className="Logo" />
          </div>
          <div className=" mt-4">
            <div className="d-flex justify-content-center justify-content-sm-start">
              <div className="me-2">
                <i className="fa fa-light fa-location-dot"></i>
              </div>
              <Link to={contactInfo.map} target="_blank">
              <div className="websiteInfo">
                  {contactInfo.address==null||contactInfo.address==''?'': parse(contactInfo.address)}
              </div>
              </Link>
            </div>
          </div>
          <div className=" mt-4">
            <div className="d-flex justify-content-center justify-content-sm-start">
              <div className="me-2">
                <i className="fa-solid fa-phone-volume"></i>
              </div>
              <a href={"tel:" + contactInfo.phone} className="websiteInfo">
                {contactInfo.phone}
              </a>
            </div>
          </div>
          <div className=" mt-4">
            <div className="d-flex justify-content-center justify-content-sm-start">
              <div className="me-2">
                <i className="fa-solid fa-envelope"></i>
              </div>
              <a href={"mailto:" + contactInfo.email} className="websiteInfo">
                {contactInfo.email}
              </a>
            </div>
          </div>
          <div className="d-flex mt-4 justify-content-center justify-content-sm-start">
            <a
              href={contactInfo.Facebook}
              target="_blank"
              className="me-3 facebook"
            >
              <i className="fa-brands fa-facebook fs-3 "></i>
            </a>
            <a
              href={contactInfo.Youtube}
              target="_blank"
              className="me-3  youtube"
            >
              <i className="fa-brands fa-youtube fs-3"></i>
            </a>
            
            <a
              href={contactInfo.LinkedIn}
              target="_blank"
              className="me-3  linkedin"
            >
              <i className="fa-brands fa-linkedin-in fs-3"></i>
            </a>

            <a
              href={contactInfo.Instagram}
              target="_blank"
              className="me-3 instagram"
            >
              <i className="fa-brands fa-instagram fs-3"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="copyRight d-flex justify-content-center align-items-center background-darkgrey">
        <div className="copyRightText color-white defaultFont">
          Copyrights {date.getFullYear()} Genesys Impact LLC - Tailored By{" "}
          <a
            className="syncButton"
            href="https://wecansync.com/"
            target="#"
          >
            SYNCUS LLC
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
