import React, { useEffect, useState } from "react";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import NewsTitle from "../Components/GlobalComponent/NewsTitle";
import "../assets/css/Aboutus.css";
import { colors } from "../Library/colors";
import { city, docter, productImage } from "../Library/icons";
import { fontFamilyRegular } from "../Library/styles";
import "../assets/css/colors.css";
import "../assets/css/Global.css";
import { useLocation } from "react-router-dom";
import { scrollToTop } from "../Library/functions";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import { get } from "../api/api";
import { getAbout, getHomePage, getImpact, getPageInfo } from "../api/url";
import { useDispatch, useSelector } from "react-redux";
import { ABOUTPAGE, HOMEPAGE, MENUOPENED } from "../Redux/actionTypes";
import Header from "../Components/GlobalComponent/Header";
import { Helmet } from "react-helmet";
import parse, { domToReact } from 'html-react-parser';
import $ from 'jquery'
const OurStrategy = () => {
  const params = useLocation();
  const [loaded, setloaded] = useState(false);
  const dispatch = useDispatch();
  const [info, setinfo] = useState({});
  const imageUrl = useSelector((state) => state.imageUrl);

  const getAboutInfo = async () => {
    // setloaded(true)
    const home = await get(getPageInfo("our_Strategie")).then((resp) => {
      // console.log(resp);
      if (resp.error) {
      } else {
        setinfo(resp.message.data.page);
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    $('.navbarlink').removeClass('active')
    $('.navbarlinks2').removeClass('active')
    $('.navbarlinks3').removeClass('active')
    $("#aboutMenu").addClass("active");
    $("#ourStrategy").addClass("active");
    dispatch({ type: MENUOPENED, value: false });

    scrollToTop()
    getAboutInfo();
    document.getElementById('root').classList.add('loaded')
  }, []);
  if (loaded) {
    return (
      <>
        <Helmet>
          <meta name="description" content={info.seoDescrition} />
          <meta name="keywords" content={info.seoKeywords} />

          <meta property="og:title" content={info.seoTitle} />
          <meta
            property="og:url"
            content={"genesysimpact-FE/build" + params.pathname}
          />
          <meta property="og:description" content={info.seoDescrition} />
          <meta property="og:image" content={imageUrl + info.image} />
          <meta property="og:type" content="Our Impact" />
          <meta property="og:site_name" content={info.seoTitle} />
          <title>{info.seoTitle}</title>
        </Helmet>
        {/* <Navbar /> */}
        <div
          className="imagecontainer3"
          style={{
            backgroundImage: `url(${imageUrl + info.image})`,
          }}
        >
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${imageUrl + info.image})`,
            }}
          ></div>
        </div>
        <div className="d-flex justify-content-center ">
          <NewsTitle title={info.shortDescription} />
        </div>
        <div className="HeaderDescription  container color-grey defaultFont reveal">
          {info.content==null||info.content==''?'':parse(info.content)  }
        </div>

        <div className="mt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default OurStrategy;
