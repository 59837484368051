import React from "react";
import "../../assets/css/Links.css";
import { colors } from "../../Library/colors";
import { fontFamilyRegular } from "../../Library/styles";
import "../../assets/css/colors.css";
import "../../assets/css/Global.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const Links = ({ pages }) => {
  const imageUrl = useSelector((state) => state.imageUrl);
  return (
    <div className="links-section reveal">
      <div className=" container  my-5">
        <div className="row">

          <div className="col-12 col-md-6 col-xl-4 d-flex my-2 justify-content-center">
            <Link to={'/work/'}>
              <div
                className="linkItems p-4 d-flex flex-column justify-content-end linksOneItem"
                style={{
                  backgroundImage: `url(${pages.homepage_work.image})`,backgroundSize:'cover',
                }}
              >
                <div className="linkTitle color-white">
                  {pages.homepage_work.title}
                </div>
                <div className="linkDescriptionContainer">
                  <div className="linkDescription color-darkgrey ">
                    {pages.homepage_work.shortDescription}
                  </div>
                  <div className=" d-flex align-items-center mt-4 color-darkgrey  swiperBtnLink">
                    <div className="linkDescription buttonLink">
                      {pages.homepage_work.buttonTitle}
                    </div>
                    <div className="ms-2 btnIcon">
                      <i className="fa fa-light fa-arrow-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4 d-flex my-2 justify-content-center">
            <Link to={'/contact/'}>
              <div
                className="linkItems p-4 d-flex flex-column justify-content-end linksOneItem"
                style={{
                  backgroundImage: `url(${pages.homepage_contact.image
                    })`,backgroundSize:'cover',
                }}
              >
                <div className="linkTitle color-white">
                  {pages.homepage_contact.title}
                </div>
                <div className="linkDescriptionContainer">
                  <div className="linkDescription color-darkgrey ">
                    {pages.homepage_contact.shortDescription}
                  </div>
                  <div className="mt-4 d-flex align-items-center color-darkgrey  swiperBtnLink">
                    <div className="linkDescription buttonLink">
                      {pages.homepage_contact.buttonTitle}
                    </div>
                    <div className="ms-2 btnIcon">
                      <i className="fa fa-light fa-arrow-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4 d-flex my-2 justify-content-center">
            <Link to={'/ourLab/'}>

              <div
                className="linkItems p-4 d-flex flex-column justify-content-end linksOneItem"
                style={{
                  backgroundImage: `url(${pages.homepage_lab.image})`,backgroundSize:'cover',
                }}
              >
                <div className="linkTitle color-white">
                  {pages.homepage_lab.title}
                </div>
                <div className="linkDescriptionContainer">
                  <div className="linkDescription color-darkgrey ">
                    {pages.homepage_lab.shortDescription}
                  </div>
                  <div className="mt-4 d-flex align-items-center color-darkgrey  swiperBtnLink">
                    <div className="linkDescription buttonLink">
                      {pages.homepage_lab.buttonTitle}
                    </div>
                    <div className="ms-2 btnIcon">
                      <i className="fa fa-light fa-arrow-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Links;
