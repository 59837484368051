import React from "react";
import HeaderDescription from "../GlobalComponent/HeaderDescription";
import NewsTitle from "../GlobalComponent/NewsTitle";
import EachNews from "./EachNews";

const UpcomingEvents = ({ Upcoming }) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        {Upcoming.length == 0 ? "" :<NewsTitle title="Upcoming Events" />}
        {/* <NewsTitle title="Upcoming Events" /> */}
      </div>
      <div className="container">
        <div className="row">
          {Upcoming.map((item, index) => (
            <EachNews
              event={true}
              id={item.id}
              image={item.image}
              title={item.title}
              description={item.shortDescription}
              buttonTitle={item.buttonTitle}
              imageDisplay={true}
              url={item.url}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default UpcomingEvents;
