import React, { useEffect, useState } from "react";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import NewsTitle from "../Components/GlobalComponent/NewsTitle";
import "../assets/css/Impact.css";
import { colors } from "../Library/colors";
import { fontFamilyRegular } from "../Library/styles";
import {
  city,
  placeholder,
  placeholder2,
  productImage,
} from "../Library/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { scrollToTop } from "../Library/functions";
import Loading from "../Components/GlobalComponent/Loading";
import "../assets/css/colors.css";
import "../assets/css/Global.css";
import Navbar from "../Components/GlobalComponent/Navbar";
import { getImpact } from "../api/url";
import { get } from "../api/api";
import { IMPACTPAGE, MENUOPENED } from "../Redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import Header from "../Components/GlobalComponent/Header";
import { ABOUTPAGE, HOMEPAGE } from "../Redux/actionTypes";
import parse, { domToReact } from "html-react-parser";
import $ from "jquery";
const Impact = () => {
  const params = useLocation();
  const [loaded, setloaded] = useState(false);
  const dispatch = useDispatch();
  const impactPage = useSelector((state) => state.impactPage);
  const imageUrl = useSelector((state) => state.imageUrl);
  const [project, setproject] = useState([]);
  const [pages, setpages] = useState(1);
  const [loadMore, setloadMore] = useState(false);
  const [noMoreProject, setnoMoreProject] = useState(false);
  const getImpactInfo = async (pages) => {
    // console.log(pages);
    const info = await get(getImpact(pages)).then((resp) => {
      if (resp.error) {
      } else {
        dispatch({ type: IMPACTPAGE, value: resp.message.data });
        setproject(resp.message.data.projects);
        setloaded(true);
        setloadMore(false);
      }
    });
  };
  const loadeMoreProject = async (pages) => {
    // console.log(pages);
    const info = await get(getImpact(pages)).then((resp) => {
      if (resp.error) {
      } else {
        let demo = project;
        resp.message.data.projects.map((item) => {
          project.push(item);
        });
        if (resp.message.data.projects.length == 0) {
          setnoMoreProject(true);
        }
        setproject(demo);
        setloaded(true);
        setloadMore(false);
      }
    });
  };
  useEffect(() => {
    $(".navbarlink").removeClass("active");
    $(".navbarlinks2").removeClass("active");
    $('.navbarlinks3').removeClass('active')
    $("#aboutMenu").addClass("active");
    $("#ourImpact").addClass("active");
    dispatch({ type: MENUOPENED, value: false });
    scrollToTop();
    getImpactInfo(1);
    document.getElementById("root").classList.add("loaded");
  }, []);
  if (loaded) {
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content={impactPage.pages.slider_impacts.seoDescrition}
          />
          <meta
            name="keywords"
            content={impactPage.pages.slider_impacts.seoKeywords}
          />

          <meta
            property="og:title"
            content={impactPage.pages.slider_impacts.seoTitle}
          />
          <meta
            property="og:url"
            content={"genesysimpact-FE/build" + params.pathname}
          />
          <meta
            property="og:description"
            content={impactPage.pages.slider_impacts.seoDescrition}
          />
          <meta
            property="og:image"
            content={imageUrl + impactPage.pages.slider_impacts.image}
          />
          <meta property="og:type" content="Our Impact" />
          <meta
            property="og:site_name"
            content={impactPage.pages.slider_impacts.seoTitle}
          />
          <title>{impactPage.pages.slider_impacts.seoTitle}</title>
        </Helmet>
        {/* <Navbar /> */}
        <div
          className="imageContainer6"
          style={{
            backgroundImage: `url(${
              imageUrl + impactPage.pages.slider_impacts.image
            })`,
          }}
        >
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${
                imageUrl + impactPage.pages.slider_impacts.image
              })`,
            }}
          ></div>
          <Header
            leftBorder={false}
            rightBorder={false}
            topBorder={true}
            bottomBorder={false}
            rules={[]}
            firstTitle=""
            secondTitle={impactPage.pages.slider_impacts.title}
            description={impactPage.pages.slider_impacts.shortDescription}
          />
        </div>
        <div className="d-flex justify-content-center">
          <NewsTitle title={impactPage.pages.slider_impacts.title} />
        </div>
        <div className="HeaderDescription container color-grey defaultFont reveal">
          {impactPage.pages.slider_impacts.content == null ||
          impactPage.pages.slider_impacts.content == ""
            ? ""
            : parse(impactPage.pages.slider_impacts.content)}
        </div>
        <div className="container my-5">
          <div className="row d-flex justify-content-center">
            {impactPage.labels.map((item, index) => (
              <div
                className="col-12 col-md-4 col-lg-3 col-xl-2 p-3 m-2 background-lightgrey reveal"
                key={index}
              >
                <div className="d-flex flex-column align-items-center">
                  <div
                    className="impactNumber"
                    style={{ fontFamily: fontFamilyRegular, color: "black" }}
                  >
                    {item.title}
                  </div>
                  <div className="impactDescription py-3 color-grey defaultFont">
                    {item.shortDescription}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="container">
          {project.map((item, index) => {
            if (index % 2 == 0) {
              return (
                <Link to={"/singleProject/" + item.id + "/" + item.title}>
                  <div
                    className="my-5 mx-sm-3 m-0 row EachItem "
                    key={index}
                    style={{
                      backgroundImage: `url(${imageUrl + item.image})`,
                    }}
                  >
                    <div
                      className="phoneImage"
                      style={{
                        backgroundImage: `url(${imageUrl + item.image})`,
                      }}
                    ></div>
                    <div className="col-12 p-0 d-flex justify-content-center my-0 my-sm-5 reveal  justify-content-md-end align-items-end">
                      <div className="p-0">
                        <HeaderDescription
                          leftBorder={false}
                          rightBorder={false}
                          topBorder={false}
                          bottomBorder={false}
                          buttonDisplay={true}
                          rules={[]}
                          buttonTitle={item.buttonTitle}
                          firstTitle={"Project" + " " + (index + 1)}
                          secondTitle={item.title}
                          description={item.shortDescription}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              );
            } else {
              return (
                <Link to={"/singleProject/" + item.id + "/" + item.title}>
                  <div
                    className="my-5 mx-sm-3 m-0 row EachItem my-5"
                    key={index}
                    style={{
                      backgroundPositionX: "right",
                      backgroundImage: `url(${imageUrl + item.image})`,
                    }}
                  >
                    <div
                      className="phoneImage"
                      style={{
                        backgroundImage: `url(${imageUrl + item.image})`,
                      }}
                    ></div>
                    <div className="col-12 p-0 d-flex justify-content-center reveal   justify-content-md-start align-items-end">
                      <div style={{ zIndex: 2 }} className="mb-0 mb-md-4">
                        <HeaderDescription
                          leftBorder={false}
                          rightBorder={false}
                          topBorder={false}
                          bottomBorder={false}
                          buttonDisplay={true}
                          rules={[]}
                          buttonTitle={item.buttonTitle}
                          firstTitle={"Project" + " " + (index + 1)}
                          secondTitle={item.title}
                          description={item.shortDescription}
                        />
                      </div>
                      <div className="blueContainer background-lightblue"></div>
                    </div>
                  </div>
                </Link>
              );
            }
          })}
        </div>
        <div
          className="my-5"
          style={{ display: noMoreProject == true ? "none" : "block" }}
        >
          <div className="container d-flex justify-content-center">
            <div
              className="color-blue loadMoreButton"
              style={{ display: loadMore == false ? "flex" : "none" }}
              onClick={() => {
                setpages(pages + 1);
                setloadMore(true);
                loadeMoreProject(pages + 1);
              }}
            >
              Load More
            </div>
            <div
              style={{
                display: loadMore == true ? "flex" : "none",
              }}
            >
              <div className="impact-spinner-container d-flex justify-content-center align-items-center">
                <div className="loading-spinner"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Impact;
