import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { get, post } from "../api/api";
import { contactform, getPageInfo } from "../api/url";
import "../assets/css/Contactus.css";
import Footer from "../Components/GlobalComponent/Footer";
import Header from "../Components/GlobalComponent/Header";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import { scrollToTop } from "../Library/functions";
import { productImage } from "../Library/icons";
import { MENUOPENED } from "../Redux/actionTypes";
import $ from "jquery";
import parse from 'html-react-parser';

const Contactus = () => {
  const [loaded, setloaded] = useState(false);
  const params = useLocation();
  const [data, setdata] = useState({});
  const [error, setError] = useState(false);
  const [success, setsuccess] = useState(false);
  const [loading, setloading] = useState(false);
  const imageUrl = useSelector((state) => state.imageUrl);
  const dispatch=useDispatch()
  const getInfo = async () => {
    const info = await get(getPageInfo("slider_contact")).then((resp) => {
      if (resp.error) {
      } else {
        setdata(resp.message.data.page);
      }
    });
  };
  // console.log(params)
  const SubmitForm = async () => {
    setloading(true);
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const topic = document.getElementById("subject").value;
    const content = document.getElementById("message").value;
    if (name == "" || email == "" || topic == "" || content == "") {
      setError(true);
      setloading(false);
    } else {
      let message = await post(contactform(), {
        name: name,
        email: email,
        content: content,
        subject: topic,
      }).then((resp) => {
        // console.log(resp);
        if (resp.error) {
          setloading(false);
        } else {
          setloading(false);
          setError(false);
          setsuccess(true);
        }
      });
    }
  };
  useEffect(() => {
    $('.navbarlink').removeClass('active')
    $('.navbarlinks2').removeClass('active')
    $('.navbarlinks3').removeClass('active')
    $('#contactMenu').addClass('active')
    dispatch({ type: MENUOPENED, value: false });
    scrollToTop()
    getInfo();
    document.getElementById('root').classList.add('loaded')
    setloaded(true);
  }, []);
  if (loaded) {
    return (
      <div>
        <Helmet>
          <meta name="description" content={data.seoDescrition} />
          <meta name="keywords" content={data.seoKeywords} />

          <meta property="og:title" content={data.seoTitle} />
          <meta
            property="og:url"
            content={"genesysimpact-FE/build" + params.pathname}
          />
          <meta property="og:description" content={data.seoDescrition} />
          <meta property="og:image" content={imageUrl + data.image} />
          <meta property="og:type" content="Contact Us" />
          <meta property="og:site_name" content={data.seoTitle} />
          <title>{data.seoTitle}</title>
        </Helmet>
        {/* <Navbar /> */}
        <div className="imageHeaderContainer">
          <div
            className="imageContainer5"
            style={{ backgroundImage: `url(${imageUrl + data.image})` }}
          ></div>
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${imageUrl + data.image})`,
            }}
          ></div>
          <div className="position-absolute contactUsSlider" style={{ bottom: 60}}>
            <Header
              leftBorder={false}
              rightBorder={false}
              topBorder={false}
              bottomBorder={true}
              textalignment={true}
              rules={[]}
              firstTitle=""
              secondTitle="Contact Us"
              description={data.shortDescription}
            />
          </div>
        </div>
        <div className="container mb-4">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div
                className="color-red"
                style={{ display: error == true ? "flex" : "none" }}
              >
                Please all field are required
              </div>
              <div
                className="color-green"
                style={{ display: success == true ? "flex" : "none" }}
              >
                Contact Form Successful
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4">
              <div className="form-group">
                <label className="form-label" id="nameLabel" for="name"></label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="Nmae"
                  placeholder=" Name"
                  tabindex="1"
                />
              </div>

              <div className="form-group">
                <label
                  className="form-label"
                  id="emailLabel"
                  for="email"
                ></label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder=" Email"
                  tabindex="2"
                />
              </div>

              <div className="form-group">
                <label
                  className="form-label"
                  id="subjectLabel"
                  for="sublect"
                ></label>
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  name="subject"
                  placeholder="Topic"
                  tabindex="3"
                />
              </div>

              <div className="form-group">
                <label
                  className="form-label"
                  id="messageLabel"
                  for="message"
                ></label>
                <textarea
                  rows="6"
                  cols="60"
                  name="message"
                  className="form-control"
                  id="message"
                  placeholder="Your message"
                  tabindex="4"
                ></textarea>
              </div>

              <div className="text-center margin-top-25">
                <button
                  type="submit"
                  className="btn btn-mod btn-border btn-large"
                  onClick={() => {
                    SubmitForm();
                  }}
                >
                  submit
                </button>
              </div>
              <div
                className="text-center margin-top-25"
                style={{ display: loading ? "flex" : "none" }}
              >
                <div className="impact-spinner-container d-flex justify-content-center align-items-center">
                  <div className="loading-spinner"></div>
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <Footer />
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default Contactus;
