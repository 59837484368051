import React, { useEffect, useState } from "react";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import NewsTitle from "../Components/GlobalComponent/NewsTitle";
import OtherNews from "../Components/News/OtherNews";
import UpcomingEvents from "../Components/News/UpcomingEvents";
import Footer from "../Components/GlobalComponent/Footer";
import "../assets/css/News.css";
import { colors } from "../Library/colors";
import { placeholder, productImage } from "../Library/icons";
import { fontFamilyRegular } from "../Library/styles";
import { useLocation } from "react-router-dom";
import { scrollToTop } from "../Library/functions";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import { get } from "../api/api";
import { getNews } from "../api/url";
import { useDispatch, useSelector } from "react-redux";
import { MENUOPENED, NEWSPAGE } from "../Redux/actionTypes";
import { Helmet } from "react-helmet";
import Header from "../Components/GlobalComponent/Header";
import $ from "jquery";
import parse from "html-react-parser";

const News = () => {
  const state = useLocation();
  const [loaded, setloaded] = useState(false);
  const dispatch = useDispatch();
  const newsPage = useSelector((state) => state.newsPage);
  const imageUrl = useSelector((state) => state.imageUrl);
  const getNewsInfo = async () => {
    const news = await get(getNews()).then((resp) => {
      // console.log(resp);
      if (resp.error) {
      } else {
        dispatch({ type: NEWSPAGE, value: resp.message.data });
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    $(".navbarlink").removeClass("active");
    $(".navbarlinks2").removeClass("active");
    $('.navbarlinks3').removeClass('active')
    dispatch({ type: MENUOPENED, value: false });
    scrollToTop();
    getNewsInfo();
    document.getElementById("root").classList.add("loaded");
  }, []);
  if (loaded) {
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content={newsPage.pages.slider_news.seoDescrition}
          />
          <meta
            name="keywords"
            content={newsPage.pages.slider_news.seoKeywords}
          />

          <meta
            property="og:title"
            content={newsPage.pages.slider_news.seoTitle}
          />
          <meta
            property="og:url"
            content={"genesysimpact-FE/build" + state.pathname}
          />
          <meta
            property="og:description"
            content={newsPage.pages.slider_news.seoDescrition}
          />
          <meta
            property="og:image"
            content={imageUrl + newsPage.pages.slider_news.image}
          />
          <meta property="og:type" content="Capabilities" />
          <meta
            property="og:site_name"
            content={newsPage.pages.slider_news.seoTitle}
          />
          <title>{newsPage.pages.slider_news.seoTitle}</title>
        </Helmet>

        {/* <Navbar /> */}
        <div
          className="imageContainer4"
          style={{
            backgroundImage: `url(${
              imageUrl + newsPage.pages.slider_news.image
            })`,
          }}
        >
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${
                imageUrl + newsPage.pages.slider_news.image
              })`,
            }}
          ></div>
          <Header
            leftBorder={true}
            rightBorder={false}
            topBorder={false}
            bottomBorder={false}
            rules={[]}
            firstTitle=""
            secondTitle={newsPage.pages.slider_news.title}
            description={
              newsPage.pages.slider_news.content == "" ||
              newsPage.pages.slider_news.content == null
                ? ""
                : parse(newsPage.pages.slider_news.content)
            }
          />
        </div>
        <UpcomingEvents Upcoming={newsPage.events} />
        <OtherNews newNews={newsPage.news} />
        <div className="pt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default News;
