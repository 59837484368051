import React from "react";
import { colors } from "../../Library/colors";
import { pc, placeholder } from "../../Library/icons";
import { fontFamilyRegular } from "../../Library/styles";
import "../../assets/css/News.css";
import "../../assets/css/Global.css";
import "../../assets/css/colors.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const EachNews = ({
  imageDisplay,
  title,
  description,
  buttonTitle,
  id,
  event,
  image,
  url,
}) => {
  const navigate = useNavigate();
  const imageUrl = useSelector((state) => state.imageUrl);
  return (
    
    <div className="col-12 col-md-6 col-lg-4 p-2 reveal">
      {url == null || url == "" ? (
        <Link
          to={event == true ? "/singleEvent/" + id + "/" + title : "/contact"}
        >
          
          <div
            className=" d-flex flex-column p-3 background-whitegrey justify-content-between "
            style={{ minHeight: 200 }}
          >
            <img
              style={{
                width: "100%",
                height: 200,
                display: imageDisplay ? "flex" : "none",
              }}
              src={imageUrl + image}
            />
            <div className="itemTitle my-2 color-grey defaultFontBold news-title">
              {title}
            </div>
            <div className="itemDescription color-grey defaultFont news-description">
              {description}
            </div>
            <div className=" my-2">
              <div className="d-flex align-items-center newsButton ">
                <div className="itemButton color-grey defaultFont">
                  {buttonTitle}
                </div>

                <div className="ms-2 linkIcon ">
                  <i className="fa fa-light fa-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <>
          <a href={url} target="_blank">
            <div
              className=" d-flex flex-column p-3 background-whitegrey justify-content-between "
              style={{ minHeight: 200 }}
            >
              <img
                style={{
                  width: "100%",
                  height: 200,
                  display: imageDisplay ? "flex" : "none",
                }}
                src={imageUrl + image}
              />
              <div className="itemTitle my-2 color-grey defaultFontBold">
                {title}
              </div>
              <div className="itemDescription color-grey defaultFont ">
                {description}
              </div>
              <div className=" my-2">
                <div className="d-flex align-items-center newsButton ">
                  <div className="itemButton color-grey defaultFont">
                    {buttonTitle}
                  </div>

                  <div className="ms-2 linkIcon ">
                    <i className="fa fa-light fa-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </>
      )}
    </div>
  );
};

export default EachNews;
