import React from "react";
import HeaderDescription from "../GlobalComponent/HeaderDescription";
import NewsTitle from "../GlobalComponent/NewsTitle";
import EachProjects from "./EachProjects";
import "../../assets/css/News.css";
const OtherProjects = ({ projects }) => {
  console.log("projects:", projects);
  return (
    <>
          {projects.categories.map((item, index) => (
            <EachProjects
              id={item.category.id}
              title={item.category.title}
              items={item.category.project}
            />
          ))}
    </>
  );
};

export default OtherProjects;
