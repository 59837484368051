const initialState = {
    hello:'Hello',
    imageUrl:"https://genesysapi.sync.com.cy/uploads/images/",
    homePage:[],
    aboutPage:{},
    contactPage:[],
    impactPage:[],
    jobsPage:[],
    marketPages:[],
    newsPage:[],
    markets:[],
    capabilities:[],
    footerItems:[],
    contactInfo:{},
    menuOpened: false

    
};
export { initialState };
