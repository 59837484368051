import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { get } from "../api/api";
import {
  getHomePage,
  getSingleCapabilities,
  getSingleMarket,
} from "../api/url";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import PageHeader from "../Components/GlobalComponent/PageHeader";
import { scrollToTop } from "../Library/functions";
import { army, city, docter, productImage, us } from "../Library/icons";
import { MENUOPENED } from "../Redux/actionTypes";
import $ from "jquery";
import parse from 'html-react-parser';

const Markets = () => {
  const params = useParams();
  const state = useLocation();
  const dispatch=useDispatch()
  const [loaded, setloaded] = useState(false);
  const [marketInfo, setmarketInfo] = useState({});
  const imageUrl = useSelector((state) => state.imageUrl);
  const getmarketInfo = async () => {
    setloaded(false);
    // console.log('ndjsabjs')
    const home = await get(getSingleMarket(params.id)).then((resp) => {
      // console.log(resp);
      if (resp.error) {
      } else {
        setmarketInfo(resp.message.data.market);
        setloaded(true);
      }
    });
  };
  useEffect(() => {

    $('.navbarlinks2').removeClass('active')
    $('.navbarlink').removeClass('active')
    $('.navbarlinks3').removeClass('active')
    $('#marketsMenu-'+params.id).addClass('active')
    $('#marketsMenu').addClass('active')
    dispatch({ type: MENUOPENED, value: false });

    scrollToTop()
    getmarketInfo();
    document.getElementById('root').classList.add('loaded')
  }, []);
  useEffect(() => {
    $('.navbarlinks2').removeClass('active')
    $('.navbarlink').removeClass('active')
    $('.navbarlinks3').removeClass('active')
    $('#marketsMenu-'+params.id).addClass('active')
    $('#marketsMenu').addClass('active')
    dispatch({ type: MENUOPENED, value: false });
    scrollToTop()
    getmarketInfo();
    document.getElementById('root').classList.add('loaded')
  }, [params.id]);
  if (loaded) {
    return (
      <>
        <Helmet>
          <meta name="description" content={marketInfo.seoDescrition} />
          <meta name="keywords" content={marketInfo.seoKeywords} />

          <meta property="og:title" content={marketInfo.seoTitle} />
          <meta
            property="og:url"
            content={"genesysimpact-FE/build" + state.pathname}
          />
          <meta property="og:description" content={marketInfo.seoDescrition} />
          <meta property="og:image" content={imageUrl + marketInfo.image} />
          <meta property="og:type" content="Capabilities" />
          <meta property="og:site_name" content={marketInfo.seoTitle} />
          <title>{marketInfo.seoTitle ? marketInfo.seoTitle : marketInfo.title}</title>
        </Helmet>
        {/* <Navbar /> */}
        <PageHeader
        image={marketInfo.imageAdditional}
          title={marketInfo.title}
          description={marketInfo.shortDescription}
          content={marketInfo.content}
          classname= "phoneImage2"
        />
        <div style={{ overflow: "hidden" }} className="mx-sm-5 m-0">
          <div className="container p-0">
            {marketInfo.features.map((item, index) => {
              // console.log('ITEM ',item)
              if (index % 2 == 0) {
                return (
                  <div
                    className="mt-5 mb-3  m-0 row EachItem  "
                    key={index}
                    style={{ backgroundImage: `url(${imageUrl + item.image})` }}
                  >
                    <div
                      className="phoneImage2"
                      style={{
                        backgroundImage: `url(${imageUrl + item.image})`,
                      }}
                    ></div>
                    <div className="col-12 p-0 d-flex justify-content-center my-5  justify-content-md-end  reveal">
                      <div className="p-0">
                        <HeaderDescription
                          leftBorder={true}
                          rightBorder={false}
                          topBorder={false}
                          bottomBorder={false}
                          buttonDisplay={false}
                          rulesDisplay={item.feature.length == 0 ? false : true}
                          rules={item.feature}
                          firstTitle=""
                          secondTitle={item.shortDescription}
                          description={item.content==''||item.content==null?'': parse(item.content)}
                        />
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="mt-5 mb-3  m-0 row EachItem "
                    style={{
                      backgroundPositionX: "right",
                      backgroundImage: `url(${imageUrl + item.image})`,
                    }}
                    key={index}
                  >
                    <div
                      className="phoneImage2"
                      style={{
                        backgroundImage: `url(${imageUrl + item.image})`,
                      }}
                    ></div>
                    <div className="col-12 p-0 d-flex justify-content-center  my-5  justify-content-md-start  reveal">
                      <div>
                        <HeaderDescription
                          leftBorder={false}
                          rightBorder={true}
                          topBorder={false}
                          bottomBorder={false}
                          buttonDisplay={false}
                          rulesDisplay={item.feature.length == 0 ? false : true}
                          rules={item.feature}
                          firstTitle=""
                          secondTitle={item.shortDescription}
                          description={item.content==''||item.content==null?'': parse(item.content)}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            })}

            {/* <div
              className="my-5  m-0 row EachItem"
              style={{
                backgroundPositionX: "right",
                backgroundSize: "60% 100%",
                height: 900,
                backgroundImage: `url(${us})`,
              }}
            >
              <div
                className="phoneImage"
                style={{
                  backgroundImage: `url(${us})`,
                }}
              ></div>
              <div className="col-12 p-0 d-flex  justify-content-center  justify-content-md-start align-items-center">
                <div>
                  <HeaderDescription
                    leftBorder={false}
                    rightBorder={true}
                    topBorder={false}
                    bottomBorder={false}
                    buttonDisplay={false}
                    rulesDisplay={true}
                    rules={[]}
                    firstTitle="STATE AND LOCAL GOVERNMENT"
                    secondTitle="Delivering for every 
                  level of government.
                "
                    description="We partner with state and local governments across the United States to identify and deliver the right infrastructure, business solutions, and digital-transformation services to meet their goals and better serve their citizens.
  
                  "
                  />
                </div>
              </div>
            </div> */}
          </div>

          {/* <div className="py-5 "></div> */}
        </div>
        <Footer />
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Markets;
