import React, { useEffect, useState } from "react";
import "../../assets/css/OurImpact.css";
import { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../assets/css/Global.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import GeneralTitle from "../GlobalComponent/GeneralTitle";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import $ from "jquery";
import { Logo } from "../../Library/icons";
import placeholder from "../../assets/icons/gradient.jpeg";
const OurImpact = ({ impacts }) => {
  const [activeSlide, setactiveSlide] = useState(0);
  const [slideItem, setslideItem] = useState(3.5);
  const imageUrl = useSelector((state) => state.imageUrl);
  const [loaded, setloaded] = useState(false);
  // const handleResize = () => {
  //   if (window.innerWidth < 992 && window.innerWidth >= 576) {
  //     setslideItem(2);
  //   } else {
  //     if (window.innerWidth < 576) {
  //       setslideItem(1);
  //     } else {
  //       if (window.innerWidth < 1900) {
  //         setslideItem(3.5);
  //       } else {
  //         if (window.innerWidth < 2500) {
  //           setslideItem(4.5);
  //         }else{
  //           setslideItem(7);
  //         }
  //       }
  //     }
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  // });
  // useEffect(() => {
  //   if (window.innerWidth < 992 && window.innerWidth >= 576) {
  //     setslideItem(2);
  //   } else {
  //     if (window.innerWidth < 576) {
  //       setslideItem(1);
  //     } else {
  //       if (window.innerWidth < 1900) {
  //         setslideItem(3.5);
  //       } else {
  //         if (window.innerWidth < 2500) {
  //           setslideItem(4.5);
  //         }else{
  //           setslideItem(6);
  //         }
  //       }
  //     }
  //   }
  // }, []);

  return (
    <>
      <div className=" d-flex flex-column mt-0 mt-sm-5 impact-container reveal">
        <div className="d-flex justify-content-start mt-3 ms-3 ms-sm-5 generalTitle">
          <GeneralTitle Title="Our Impact" />
        </div>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          // slidesPerView={slideItem}
          breakpoints={{
            0:{
              slidesPerView: 1,
              spaceBetween: 0,
              touchRatio: 0
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 0,
              touchRatio: 0

            },
            992: {
              slidesPerView: 2,
              spaceBetween: 0,
              touchRatio: 0

            },
            1024: {
              slidesPerView: 3.3,
              spaceBetween:5,
              touchRatio: 0
            },
            1200: {
              slidesPerView: 3.6,
              spaceBetween:5,
              touchRatio: 0
            },
            1400: {
              slidesPerView: 3.9,
              spaceBetween:5,
              touchRatio: 0
            },
            1600: {
              slidesPerView: 5.5,
              spaceBetween:10,
              touchRatio: 0
            },
            1800: {
              slidesPerView: 5.5,
              spaceBetween:10,
              touchRatio: 0
            },
            2000: {
              slidesPerView: 5.5,
              spaceBetween:20,
              touchRatio: 0
            },
            2200: {
              slidesPerView: 4.8,
              spaceBetween:30,
              touchRatio: 0
            },
            2400: {
              slidesPerView: 5,
              spaceBetween:40,
              touchRatio: 0
            },
            2600: {
              slidesPerView: 6,
              spaceBetween: 50,
              touchRatio: 0
            },
            2800: {
              slidesPerView: 6.5,
              spaceBetween: 50,
              touchRatio: 0
            },
            3000: {
              slidesPerView: 7,
              spaceBetween: 50,
              touchRatio: 0

            },
            3500: {
              slidesPerView: 7,
              spaceBetween: 70,
              touchRatio: 0
            }
            ,
            4000: {
              slidesPerView: 7,
              spaceBetween: 100,
              touchRatio: 0
            }
            ,
            5000: {
              slidesPerView: 7,
              spaceBetween: 200,
              touchRatio: 0
            },
            6000: {
              slidesPerView: 7,
              spaceBetween: 250,
              touchRatio: 0
            },
            7000: {
              slidesPerView: 7,
              spaceBetween: 300,
              touchRatio: 0
            }
          }}
          draggable={false}
          navigation={true}
          loop={true}
          // pagination={true}
          modules={[Pagination, Navigation]}
          onInit={(resp) => {
            setTimeout(() => {
              $(".impactSwiper .swiper-slide")
                .removeClass("left2")
                .removeClass("right2")
                .removeClass("left3")
                .removeClass("right3")
                .removeClass("left4")
                .removeClass("right4");

              let prev = $(".impactSwiper .swiper-slide.swiper-slide-prev").prev();
              prev.addClass("left2");

              let prev2 = prev.prev();
              prev2.addClass("left3");

              let next = $(".impactSwiper .swiper-slide.swiper-slide-next").next();
              next.addClass("right2");

              let next2 = next.next();
              next2.addClass("right3");
            }, 100);
          }}
          className="impactSwiper mb-4"
          onSnapIndexChange={(resp) => {
            // console.log(resp.activeIndex)
            setTimeout(() => {
              $(".impactSwiper .swiper-slide")
                .removeClass("left2")
                .removeClass("right2")
                .removeClass("left3")
                .removeClass("right3")
                .removeClass("left4")
                .removeClass("right4");

              let prev = $(".impactSwiper .swiper-slide.swiper-slide-prev").prev();
              prev.addClass("left2");

              let prev2 = prev.prev();
              prev2.addClass("left3");

              let next = $(".impactSwiper .swiper-slide.swiper-slide-next").next();
              next.addClass("right2");

              let next2 = next.next();
              next2.addClass("right3");
            }, 100);

            // setactiveSlide(resp.activeIndex);
          }}
        >
          {impacts.map((item, index) => {
            // console.log(index)
            return (
              <SwiperSlide key={index}>
                <Link to={"/singleProject/" + item.id + "/" + item.slug}>
                  <div
                    className="p-3  d-flex flex-column"
                    style={
                      {
                        // backgroundColor:
                        //   activeSlide == index  ? "#D9D9D9" : "transparent",
                        // minHeight:550
                      }
                    }
                  >
                    <div className="w-100 imapctImage">
                      <img
                        src={placeholder}
                        style={{
                          width: "100%",
                          height: "100%",
                          display: loaded ? "none" : "block",
                        }}
                      />
                      <img
                        onLoad={() => {
                          setloaded(true);
                        }}
                        className="secondImage"
                        src={item.image}
                        style={{
                          width: "100%",
                          height: "100%",
                          display: loaded ? "block" : "none",
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column py-2">
                      <h4
                        className="impactTitle  color-darkgrey"
                        // style={{ display: activeSlide == index  ? "flex" : "none" }}
                      >
                        {item.title}
                      </h4>
                      <div
                        className="impactdescription  color-grey mt-2 "
                        // style={{ display: activeSlide == index  ? "flex" : "none" }}
                      >
                        {item.shortDescription}
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default OurImpact;
