import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../assets/css/NewsEvents.css";
import "../../assets/css/Links.css";
import { colors } from "../../Library/colors";
import { fontFamilyRegular } from "../../Library/styles";
import GeneralTitle from "../GlobalComponent/GeneralTitle";
import "../../assets/css/colors.css";
import "../../assets/css/Global.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import placeholder from '../../assets/icons/gradient.jpeg';
const NewsEvents = ({ news }) => {
  const imageUrl = useSelector((state) => state.imageUrl);
  const [loaded, setloaded] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4.5,
    },
    tablet: {
      breakpoint: { max: 1400, min: 1024 },
      items: 3.5,
    },
    tablet: {
      breakpoint: { max: 1023, min: 768 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 767, min: 569 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 568, min: 200 },
      items: 1,
    },
  };
  const [slideItem, setslideItem] = useState(4.5);
  const handleResize = () => {
    if (window.innerWidth < 992 && window.innerWidth >= 576) {
      setslideItem(2.3);
    } else {
      if (window.innerWidth < 1400 && window.innerWidth > 992) {
        setslideItem(3.5);
      } else {
        if (window.innerWidth < 576) {
          setslideItem(1);
        } else {
          setslideItem(4.5);
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });
  useEffect(() => {
    if (window.innerWidth < 992 && window.innerWidth >= 576) {
      setslideItem(2.3);
    } else {
      if (window.innerWidth < 1400 && window.innerWidth > 992) {
        setslideItem(3.5);
      } else {
        if (window.innerWidth < 576) {
          setslideItem(1);
        } else {
          setslideItem(4.5);
        }
      }
    }
  }, []);
  return (
    <div className=" d-flex flex-column newsSection reveal">
      <div className="ps-3 ps-sm-5 d-flex justify-content-start mt-3 ms-3 ms-sm-5 generalTitle">
        <GeneralTitle Title={"News & Events"} />
      </div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        // centeredSlides={true}
        slidesPerView={slideItem}
        navigation={true}
        // loop={true}
        // pagination={true}
        modules={[Pagination, Navigation]}
        className="newsSwiper"
      >
        {news.map((item, index) => {
          return (
            <SwiperSlide  key={index}>
              <Link
                to={"/singleEvent/" + item.id + "/" + item.slug}
                className="eventItem"
              >
                <div className=" me-0   eventItemHover">
                  <div className=" p-3">
                    <div className="EventImage">
                      <img
                        src={placeholder}
                        style={{
                          width: "100%",
                          display: loaded ? "none" : "block",
                        }}
                      />
                      <img
                        onLoad={() => {
                          setloaded(true);
                        }}
                        src={item.image}
                        className='eventImage'
                        style={{
                          width: "100%",
                          display: loaded ? "block" : "none",
                        }}
                      />
                    </div>
                    <div className="cardTitle my-2 color-darkgrey news-events-title">
                      {item.title}
                    </div>
                    <div className="cardDesctiption color-grey news-events-description">
                      {item.shortDescription}
                    </div>
                    <div className="mt-2 d-flex align-items-center swiperBtnLink">
                      <div className="linkDescription color-darkgrey ">
                        {item.buttonTitle}
                      </div>
                      <div className="ms-2 linkIcon ">
                        <i className="fa fa-light fa-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <Carousel
        responsive={responsive}
        containerClass="carousel-container"
        itemClass="CarouselItem"
      >
        {news.map((item, index) => {
          return (
            <Link to={"/singleEvent/" + item.id + "/" + item.title} className='eventItem'>
            <div className=" me-0 me-sm-2  eventItemHover" key={index}>
              <div className=" p-3">
                <div style={{ width: "100%", height: 250 }}>
                  <img
                    src={imageUrl + item.image}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div className="cardTitle my-2 color-darkgrey defaultFontBold">
                  {item.title}
                </div>
                <div className="cardDesctiption color-grey defaultFont">
                  {item.shortDescription}
                </div>
                <div className="mt-2 d-flex align-items-center ">
                  <div className="linkDescription color-darkgrey defaultFont">
                    {item.buttonTitle}
                  </div>
                  <div className="ms-2">
                    <i className="fa fa-light fa-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
            </Link>
            
          );
        })}

        <div className=""></div>
      </Carousel> */}
    </div>
  );
};

export default NewsEvents;
