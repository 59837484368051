import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { HashRouter } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { createRoot,hydrateRoot } from 'react-dom/client'

import { hydrate, render } from "react-dom";
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);
