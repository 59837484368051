export const HOMEPAGE="HOMEPAGE"
export const ABOUTPAGE="ABOUTPAGE"
export const CONTACTPAGE="CONTACTPAGE"
export const IMPACTPAGE="IMPACTPAGE"
export const JOBSPAGE="JOBSPAGE"
export const MARKETPAGES="MARKETPAGES"
export const NEWSPAGE="NEWSPAGE"
export const FOOTERITEMS="FOOTERITEMS"
export const CAPABILITIES="CAPABILITIES"
export const MARKETS="MARKETS"
export const CONTACTINFO="CONTACTINFO"
export const MENUOPENED="MENUOPENED"
export const PROJECTSPAGE = "PROJECTSPAGE"



