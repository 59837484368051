import React from 'react';
import '../../assets/css/Global.css'
const Loading = (props) => {
  return (
    <div className="spinner-container d-flex justify-content-center align-items-center">
      <div className="loading-spinner">
      </div>
    </div>
  );
};

export default Loading;