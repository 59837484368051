import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { get } from "../api/api";
import { getSingleNews } from "../api/url";
import Footer from "../Components/GlobalComponent/Footer";
import Header from "../Components/GlobalComponent/Header";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import parse, { domToReact } from "html-react-parser";
import { scrollToTop } from "../Library/functions";
import { MENUOPENED } from "../Redux/actionTypes";
import $ from "jquery";
const SingleEvents = () => {
  const params = useParams();
  const state = useLocation();
  // console.log(params.id)
  const [loaded, setloaded] = useState(false);
  const [info, setinfo] = useState({});
  const imageUrl = useSelector((state) => state.imageUrl);
  const dispatch = useDispatch();
  const getevent = async () => {
    setloaded(false);
    const info = await get(getSingleNews(params.id)).then((resp) => {
      // console.log(resp);
      if (resp.error) {
      } else {
        setinfo(resp.message.data.new);
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    $(".navbarlink").removeClass("active");
    $(".navbarlinks2").removeClass("active");
    $('.navbarlinks3').removeClass('active')
    dispatch({ type: MENUOPENED, value: false });
    scrollToTop();
    getevent();
    document.getElementById("root").classList.add("loaded");
  }, [params.id]);
  // console.log(info);
  if (loaded) {
    return (
      <>
        <Helmet>
          <meta name="description" content={info.shortDescription} />
          <meta name="keywords" content={info.shortDescription} />

          <meta property="og:title" content={info.title} />
          <meta
            property="og:url"
            content={"genesysimpact-FE/build" + state.pathname}
          />
          <meta property="og:description" content={info.shortDescription} />
          <meta property="og:image" content={imageUrl + info.image} />
          <meta property="og:type" content="Events" />
          <meta property="og:site_name" content={info.title} />
          <title>{info.seoTitle ? info.seoTitle : info.title}</title>
        </Helmet>
        {/* <Navbar /> */}
        <div
          className="imageContainer4"
          style={{
            backgroundImage: `url(${imageUrl + info.image})`,
          }}
        >
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${imageUrl + info.image})`,
            }}
          ></div>
          <Header
            leftBorder={true}
            rightBorder={false}
            topBorder={false}
            bottomBorder={false}
            rules={[]}
            firstTitle=""
            secondTitle={info.title}
            description={info.shortDescription}
          />
        </div>
        <div className="my-5">
          <div className="HeaderDescription container color-grey defaultFont reveal">
            {info.content == null || info.content == ""
              ? ""
              : parse(info.content)}
          </div>
        </div>
        <div className="mt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default SingleEvents;
