import React from "react";
import "../../assets/css/Markets.css";
import { placeholder2, productImage } from "../../Library/icons";
import { colors } from "../../Library/colors";
import { fontFamilyRegular } from "../../Library/styles";
import "../../assets/css/colors.css";
import "../../assets/css/Global.css";
import parse, { domToReact } from 'html-react-parser';

const HeaderDescription = ({
  firstTitle,
  secondTitle,
  description,
  leftBorder,
  rightBorder,
  topBorder,
  bottomBorder,
  buttonDisplay,
  buttonTitle,
  rulesDisplay,
  textalignment,
  rules,
}) => {
  return (
    <div>

      <div
        className="descriptionContainer"
        style={{
          borderLeft: leftBorder == true ? "solid 10px #0588da" : "0",
          borderRight: rightBorder == true ? "solid 10px #0588da" : "0",
        }}
      >
              <div
        className="tobBorder"
        style={{ display: topBorder ? "flex" : "none" }}
      ></div>
        <div className="w-100 insideDescription" >
          <div className="firstTitle color-grey defaultFontBold ">{firstTitle}</div>

          <div
            style={{
              justifyContent: textalignment == true ? "center" : "flex-start",
            }}
            className="SecondTitle py-2  color-grey defaultFontBold  d-flex "
          >
            {secondTitle}
          </div>
          <div className="Description color-grey defaultFont ">
            {description ==null?'': description}
          </div>
          <div
            className="d-flex flex-column mt-2"
            style={{ display: rulesDisplay == true ? "flex" : "none" }}
          >
            {rules.map((item, index) => (
              <div
              key={index}
                className="eachRules px-2 mt-3 color-grey defaultFont "
                style={{
                  display: rulesDisplay == true ? "flex" : "none",
                }}
              >
                {item.title}
              </div>
            ))}
          </div>
          <div
            className="optionButton mt-2 d-flex align-items-center color-darkgrey defaultFont  "
            style={{ display: buttonDisplay ? "flex" : "none" }}
          >
            <div className="linkDescription" >
              {buttonTitle}
            </div>
            <div
              className="ms-2 btnIcon"
              style={{ display: buttonDisplay ? "flex" : "none" }}
            >
              <i className="fa fa-light fa-arrow-right "></i>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tobBorder"
        style={{ display: bottomBorder ? "flex" : "none" }}
      ></div>
    </div>
  );
};

export default HeaderDescription;
