import React from "react";
import "../../assets/css/Global.css";
import { colors } from "../../Library/colors";
import '../../assets/css/colors.css'
import { fontFamilyRegular } from "../../Library/styles";
const GeneralTitle = ({ Title }) => {
  return (
    <>
      <div>
        <h2 className="title color-darkgrey ">{Title}</h2>
      </div>
    </>
  );
};

export default GeneralTitle;
