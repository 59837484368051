const baseurl2 ="http://192.168.0.248/genesysimpact-BE/public/index.php/en/api";
const baseurl = "https://genesysapi.sync.com.cy/en/api";

const getHomePage = () => `${baseurl}/home`;
const getAbout = () => `${baseurl}/about/`;
const getContact = () => `${baseurl}/contact`;
const getImpact = (page) => `${baseurl}/impacts/${page}`;
const getJobs = () => `${baseurl}/jobs/`;
const getMarkets = () => `${baseurl}/markets`;
const getNews = () => `${baseurl}/news/`;
const getTeam = () => `${baseurl}/teams/`;
const getProjects = () => `${baseurl}/projects/list`;

const getSingleProject = (id) => `${baseurl}/projects/get/${id}`;
const getSingleMarket = (id) => `${baseurl}/markets/get/${id}`;
const getSingleCapabilities = (id) => `${baseurl}/capabilities/get/${id}`;
const getSingleNews = (id) => `${baseurl}/news/get/${id}`;
const getPageInfo = (code) => `${baseurl}/${code}/page`;

const contactform = () => `${baseurl}/contact/`; //POST
const getSearch= (keyword) => `${baseurl}/search?keyword=${keyword}`

const getFooter = () => `${baseurl}/footer`;

export {
  getHomePage,
  getAbout,
  getContact,
  getImpact,
  getJobs,
  getMarkets,
  getNews,
  getSingleProject,
  getFooter,
  getSingleCapabilities,
  getSingleMarket,
  getSingleNews,
  contactform,
  getTeam,
  getPageInfo,
  getSearch,
  getProjects,
};
