import { Provider } from "react-redux";
import { createStore } from "redux";
import "./App.css";
import HomePage from "./Screens/HomePage";
import Markets from "./Screens/Markets";
import News from "./Screens/News";
import SingleProject from "./Screens/SingleProject";
import rootReducer from "./Redux/rootReducer";
import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.min.js";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import NewsEvents from "./Components/HomePageComponent/NewsEvents";
import Work from "./Screens/Work";
import Aboutus from "./Screens/Aboutus";
import Impact from "./Screens/Impact";
import Contactus from "./Screens/Contactus";
import Ourteam from "./Screens/Ourteam";
import SingleEvents from "./Screens/SingleEvents";
import Navbar from "./Components/GlobalComponent/Navbar";
import Capabilities from "./Screens/Capabilities";
import OurLab from "./Screens/OurLab";
import OurStrategy from "./Screens/OurStrategy";
import $ from "jquery";
import Loading from "./Components/GlobalComponent/Loading";
import SearchResults from "./Screens/SearchResults";
import Technology from "./Screens/Technologies";
import OurCulture from "./Screens/OurCulture";
import Partners from "./Screens/Partners";
import CaseStudies from "./Screens/CaseStudies";
import Projects from "./Screens/Projects";
// http://localhost/genesysimpact-FE/build local Domain
// prod domain : /
//inside packag.json

//inside index.js
//prod basename=""
//local basename="/genesysimpact-FE/build"

const App = () => {
  const store = createStore(rootReducer);
  
  return (
    <>
    <div className="mainLoaded">

    <Loading/>
    </div>
    <div className="appContent">

    <Provider store={store}>
      <BrowserRouter basename="">
      <Navbar/>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="impact" element={<Impact />} />
          <Route path="/search" element={<SearchResults />} />

          <Route path="/market/:id/:name/" element={<Markets />} />
          <Route path="/capabilities/:id/:name/" element={<Capabilities />} />

          <Route path="/about/:id" element={<Aboutus />} />
          <Route path="/singleProject/:id/:name/" element={<SingleProject />} />
          <Route path="news" element={<News />} />
          <Route path="work" element={<Work />} />
          <Route path="ourLab" element={<OurLab />} />
          <Route path="technology" element={<Technology />} />
          <Route path="partners" element={<Partners />} />
          <Route path="caseStudies" element={<CaseStudies />} />
  
          <Route path="our-strategy" element={<OurStrategy />} />
          <Route path="our-culture" element={<OurCulture />} />

          <Route path="contact" element={<Contactus />} />
          <Route path="our-team" element={<Ourteam />} />
          <Route path="/singleEvent/:id/:name/" element={<SingleEvents />} />

          <Route path="projects" element={<Projects />} />
        </Routes>
      </BrowserRouter>
    </Provider>
    </div>
    </>
  );
};

export default App;
