import React, { useEffect, useState } from "react";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import NewsTitle from "../Components/GlobalComponent/NewsTitle";
import "../assets/css/Aboutus.css";
import { colors } from "../Library/colors";
import { city, docter, productImage } from "../Library/icons";
import { fontFamilyRegular } from "../Library/styles";
import "../assets/css/colors.css";
import "../assets/css/Global.css";
import { useLocation, useParams } from "react-router-dom";
import { scrollToTop } from "../Library/functions";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import { get } from "../api/api";
import { getAbout, getHomePage, getImpact, getPageInfo } from "../api/url";
import { useDispatch, useSelector } from "react-redux";
import { ABOUTPAGE, HOMEPAGE, MENUOPENED } from "../Redux/actionTypes";
import Header from "../Components/GlobalComponent/Header";
import { Helmet } from "react-helmet";
import parse, { domToReact } from "html-react-parser";
import $ from "jquery";
const Aboutus = () => {
  const params = useLocation();
  const state = useParams();

  const [loaded, setloaded] = useState(false);
  const dispatch = useDispatch();
  const aboutPage = useSelector((state) => state.aboutPage);
  const imageUrl = useSelector((state) => state.imageUrl);
  const [leftSection,setleftSection]=useState('')
  const [rightSection,setrightSection]=useState('')
  // console.log(state)
  const getAboutInfo = async () => {
    setloaded(false);
    const home = await get(getAbout()).then((resp) => {
      // console.log(resp);
      if (resp.error) {
      } else {
        dispatch({ type: ABOUTPAGE, value: resp.message.data });
        // setloaded(true);
      }
    });
    const belowaboutus = await get(getPageInfo('aboutusleftsection')).then((resp) => {
      // console.log(resp);
      if (resp.error) {
      } else {
        setleftSection(resp.message.data.page.content)
        // setloaded(true);
      }
    });
    const belowaboutusright = await get(getPageInfo('aboutusrightsection')).then((resp) => {
      // console.log(resp);
      if (resp.error) {
      } else {
        setrightSection(resp.message.data.page.content)
        setloaded(true);
      }
    });
  };

  useEffect(() => {
    $(".navbarlink").removeClass("active");
    $(".navbarlinks2").removeClass("active");
    $('.navbarlinks3').removeClass('active')
    $("#aboutMenu").addClass("active");
    if (state.id == "Culture") {
      // console.log('I AM HERE')
      $("#ourCulture").addClass("active");
      setTimeout(() => {
        $("html, body").animate(
          {
            scrollTop: $("#0").offset().top,
          },
          500
        );
      }, 500);
    }else{
      $("#ourStory").addClass("active");
    }

    dispatch({ type: MENUOPENED, value: false });
    scrollToTop();
    getAboutInfo();
    document.getElementById("root").classList.add("loaded");
  }, [state.id]);
  if (loaded) {
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content={aboutPage.pages.slider_about.seoDescrition}
          />
          <meta
            name="keywords"
            content={aboutPage.pages.slider_about.seoKeywords}
          />

          <meta
            property="og:title"
            content={aboutPage.pages.slider_about.seoTitle}
          />
          <meta
            property="og:url"
            content={"genesysimpact-FE/build" + params.pathname}
          />
          <meta
            property="og:description"
            content={aboutPage.pages.slider_about.seoDescrition}
          />
          <meta
            property="og:image"
            content={imageUrl + aboutPage.pages.slider_about.image}
          />
          <meta property="og:type" content="Our Impact" />
          <meta
            property="og:site_name"
            content={aboutPage.pages.slider_about.seoTitle}
          />
          <title>{aboutPage.pages.slider_about.seoTitle}</title>
        </Helmet>
        {/* <Navbar /> */}
        <div
          className="imagecontainer3"
          style={{
            backgroundImage: `url(${
              imageUrl + aboutPage.pages.slider_about.image
            })`,
          }}
        >
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${
                imageUrl + aboutPage.pages.slider_about.image
              })`,
            }}
          ></div>
          <Header
            leftBorder={true}
            rightBorder={false}
            topBorder={false}
            bottomBorder={false}
            buttonDisplay={false}
            rules={[]}
            firstTitle=""
            secondTitle={aboutPage.pages.slider_about.title}
            description={
              aboutPage.pages.slider_about.content == null ||
              aboutPage.pages.slider_about.content == ""
                ? ""
                : parse(aboutPage.pages.slider_about.content)
            }
          />
        </div>
        <div className="d-flex justify-content-center reveal">
          <NewsTitle title={aboutPage.pages.content_about.title} />
        </div>
        <div className="HeaderDescription container color-grey defaultFont reveal">
          {aboutPage.pages.content_about.content == null ||
          aboutPage.pages.content_about.content == ""
            ? ""
            : parse(aboutPage.pages.content_about.content)}
        </div>
        <div className="container my-5 reveal">
          <div className="row">
            <div className="col-12 col-lg-6 p-2 p-sm-5">
              <div className="topRectangle"></div>
              <div className="storyText color-grey defaultFont">
                {leftSection == null ||
                leftSection == ""
                  ? ""
                  : parse(leftSection)}
              </div>
            </div>
            <div className="col-12 col-lg-6  p-2 p-sm-5">
              <div className="topRectangle"></div>
              <div className="storyText color-grey defaultFont">
              {rightSection == null ||
                rightSection == ""
                  ? ""
                  : parse(rightSection)}
              </div>
            </div>
          </div>
        </div>
        {aboutPage.missions.map((item, index) => {
          if (index % 2 == 0) {
            return (
              <div className="container py-5 reveal" key={index} id={index}>
                <div
                  className="ourCultureContainer d-flex justify-content-center align-items-center p-0 p-sm-5"
                  style={{
                    backgroundImage: `url(${imageUrl + item.image})`,
                  }}
                >
                  <div className="containerBackground background-lightblue"></div>
                  <div
                    className="p-3 p-sm-5 background-lightwhite"
                    style={{
                      width: "80%",
                      zIndex: 2,
                    }}
                  >
                    <div className="ourCultureTitle mb-2 color-grey defaultFontBold">
                      {item.title}
                    </div>
                    <div className="ourCultureDescription color-grey defaultFont">
                      {item.content==''||item.content==null?'':parse(item.content)}
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="container p-0 reveal" key={index}>
                <div
                  className="my-5 m-0 row EachItem"
                  style={{ backgroundImage: `url(${imageUrl + item.image})` }}
                >
                  <div
                    className="phoneImage"
                    style={{
                      backgroundImage: `url(${imageUrl + item.image})`,
                    }}
                  ></div>
                  <div className="col-12 p-0 d-flex justify-content-center  justify-content-md-end align-items-end">
                    <div className="p-0">
                      <HeaderDescription
                        leftBorder={false}
                        rightBorder={true}
                        topBorder={false}
                        bottomBorder={false}
                        buttonDisplay={false}
                        rules={[]}
                        firstTitle=""
                        secondTitle={item.title}
                        description={parse(item.content)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}

        <div className="mt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Aboutus;
