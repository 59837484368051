import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { get } from "../api/api";
import { getSearch } from "../api/url";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import { scrollToTop } from "../Library/functions";
import parse from "html-react-parser";
import GeneralTitle from "../Components/GlobalComponent/GeneralTitle";
import Loading from "../Components/GlobalComponent/Loading";
import Footer from "../Components/GlobalComponent/Footer";
import { MENUOPENED } from "../Redux/actionTypes";
import $ from 'jquery'
const SearchResults = () => {
//   const params = useParams();
  const [data, setdata] = useState({});
  const imageUrl = useSelector((state) => state.imageUrl);
  const [loaded, setloaded] = useState(false);
  const dispatch=useDispatch()
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

//   const query = new URLSearchParams(location.search).get("keyword");
  useEffect(() => {
    // $("body").removeAttr("style");
    // $('.body').removeClass('modal-open')
    // $('.body').addClass('overflowBody')
    // $('.modal').removeClass('show')
    // $('.modal').removeClass('hide')
    
    // $('.modal-backdrop').removeClass('show')
    // console.log(params);
    $('.navbarlinks').removeClass('active')
    $('.navbarlinks2').removeClass('active')
    $('.navbarlinks3').removeClass('active')
    dispatch({ type: MENUOPENED, value: false });
    scrollToTop();
    document.getElementById("root").classList.add("loaded");
    getInfo();
  }, [params.keyword]);

  
  const getInfo = async () => {
    setloaded(false);
    let info = await get(getSearch(params.keyword)).then((resp) => {
      if (resp.error) {
      } else {
        setdata(resp.message.data);
        // console.log(resp.message.data);
        setloaded(true);
      }
    });
  };
  if (loaded) {
    if (
      data.projects.length == 0 &&
      data.capabilities.length == 0 &&
      data.markets.length == 0 &&
      data.news.length == 0
    ) {
      return (
        <>
          <div className="NoResult d-flex align-items-center justify-content-center">
            No results found
          </div>

          <Footer />
        </>
      );
    }
    return (
      <>
        <div
          className=" justify-content-start mt-3 ms-3 ms-sm-5 generalTitle"
          style={{ display: data.markets.length == 0 ? "none" : "flex" }}
        >
          <GeneralTitle Title="Markets" />
        </div>
        <div
          style={{
            overflowX: "hidden",
            display: data.markets.length == 0 ? "none" : "flex",
          }}
          className="mx-sm-5 m-0"
        >
          <div className="container p-0">
            {data.markets.map((item, index) => {
              // console.log('ITEM ',item)
              if (index % 2 == 0) {
                return (
                  <div
                    className="my-5  m-0 row EachItem"
                    key={index}
                    style={{ backgroundImage: `url(${imageUrl + item.image})` }}
                  >
                    <Link to={"/market/" + item.id + "/" + item.slug}>
                      <div
                        className="phoneImage"
                        style={{
                          backgroundImage: `url(${imageUrl + item.image})`,
                        }}
                      ></div>
                      <div className="col-12 p-0 d-flex justify-content-center my-5 reveal justify-content-md-end ">
                        <div className="p-0">
                          <HeaderDescription
                            leftBorder={true}
                            rightBorder={false}
                            topBorder={false}
                            bottomBorder={false}
                            buttonDisplay={false}
                            rulesDisplay={false}
                            rules={[]}
                            firstTitle={item.title}
                            secondTitle={item.shortDescription}
                            description={
                              item.content == "" || item.content == null
                                ? ""
                                : parse(item.content)
                            }
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              } else {
                return (
                  <div
                    className="my-5  m-0 row EachItem"
                    style={{
                      backgroundPositionX: "right",
                      backgroundImage: `url(${imageUrl + item.image})`,
                    }}
                    key={index}
                  >
                    <Link to={"/market/" + item.id + "/" + item.title}>
                      <div
                        className="phoneImage"
                        style={{
                          backgroundImage: `url(${imageUrl + item.image})`,
                        }}
                      ></div>
                      <div className="col-12 p-0 d-flex justify-content-center reveal  my-5  justify-content-md-start ">
                        <div>
                          <HeaderDescription
                            leftBorder={false}
                            rightBorder={true}
                            topBorder={false}
                            bottomBorder={false}
                            buttonDisplay={false}
                            rulesDisplay={false}
                            rules={[]}
                            firstTitle={item.title}
                            secondTitle={item.shortDescription}
                            description={
                              item.content == "" || item.content == null
                                ? ""
                                : parse(item.content)
                            }
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            })}
          </div>

          {/* <div className="py-5 "></div> */}
        </div>
        <div
          className=" justify-content-start mt-3 ms-3 ms-sm-5 generalTitle"
          style={{ display: data.capabilities.length == 0 ? "none" : "flex" }}
        >
          <GeneralTitle Title="Capabilities" />
        </div>
        <div
          style={{
            overflowX: "hidden",
            display: data.capabilities.length == 0 ? "none" : "flex",
          }}
          className="mx-sm-5 m-0"
        >
          <div className="container p-0">
            {data.capabilities.map((item, index) => {
              // console.log('ITEM ',item)
              if (index % 2 == 0) {
                return (
                  <div
                    className="my-5  m-0 row EachItem"
                    key={index}
                    style={{ backgroundImage: `url(${imageUrl + item.image})` }}
                  >
                    <Link to={"/capabilities/" + item.id + "/" + item.title}>
                      <div
                        className="phoneImage"
                        style={{
                          backgroundImage: `url(${imageUrl + item.image})`,
                        }}
                      ></div>
                      <div className="col-12 p-0 d-flex justify-content-center my-5 reveal  justify-content-md-end ">
                        <div className="p-0">
                          <HeaderDescription
                            leftBorder={true}
                            rightBorder={false}
                            topBorder={false}
                            bottomBorder={false}
                            buttonDisplay={false}
                            rulesDisplay={false}
                            rules={[]}
                            firstTitle={item.title}
                            secondTitle={item.shortDescription}
                            description={
                              item.content == "" || item.content == null
                                ? ""
                                : parse(item.content)
                            }
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              } else {
                return (
                  <div
                    className="my-5  m-0 row EachItem"
                    style={{
                      backgroundPositionX: "right",
                      backgroundImage: `url(${imageUrl + item.image})`,
                    }}
                    key={index}
                  >
                    <Link to={"/capabilities/" + item.id + "/" + item.title}>
                      <div
                        className="phoneImage"
                        style={{
                          backgroundImage: `url(${imageUrl + item.image})`,
                        }}
                      ></div>
                      <div className="col-12 p-0 d-flex justify-content-center  my-5 reveal  justify-content-md-start ">
                        <div>
                          <HeaderDescription
                            leftBorder={false}
                            rightBorder={true}
                            topBorder={false}
                            bottomBorder={false}
                            buttonDisplay={false}
                            rulesDisplay={false}
                            rules={[]}
                            firstTitle={item.title}
                            secondTitle={item.shortDescription}
                            description={
                              item.content == "" || item.content == null
                                ? ""
                                : parse(item.content)
                            }
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            })}
          </div>

          {/* <div className="py-5 "></div> */}
        </div>
        <div
          className=" justify-content-start mt-3 ms-3 ms-sm-5 generalTitle"
          style={{ display: data.projects.length == 0 ? "none" : "flex" }}
        >
          <GeneralTitle Title="Projects" />
        </div>
        <div
          style={{
            overflowX: "hidden",
            display: data.projects.length == 0 ? "none" : "flex",
          }}
          className="mx-sm-5 m-0"
        >
          <div className="container p-0">
            {data.projects.map((item, index) => {
              // console.log('ITEM ',item)
              if (index % 2 == 0) {
                return (
                  <div
                    className="my-5  m-0 row EachItem"
                    key={index}
                    style={{ backgroundImage: `url(${imageUrl + item.image})` }}
                  >
                    <Link to={"/singleProject/" + item.id + "/" + item.title}>
                      <div
                        className="phoneImage"
                        style={{
                          backgroundImage: `url(${imageUrl + item.image})`,
                        }}
                      ></div>
                      <div className="col-12 p-0 d-flex justify-content-center my-5 reveal  justify-content-md-end ">
                        <div className="p-0">
                          <HeaderDescription
                            leftBorder={true}
                            rightBorder={false}
                            topBorder={false}
                            bottomBorder={false}
                            buttonDisplay={false}
                            rulesDisplay={false}
                            rules={[]}
                            firstTitle={item.title}
                            secondTitle={item.title}
                            description={
                              item.content == "" || item.content == null
                                ? ""
                                : parse(item.content)
                            }
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              } else {
                return (
                  <div
                    className="my-5  m-0 row EachItem"
                    style={{
                      backgroundPositionX: "right",
                      backgroundImage: `url(${imageUrl + item.image})`,
                    }}
                    key={index}
                  >
                    <Link to={"/singleProject/" + item.id + "/" + item.title}>
                      <div
                        className="phoneImage"
                        style={{
                          backgroundImage: `url(${imageUrl + item.image})`,
                        }}
                      ></div>
                      <div className="col-12 p-0 d-flex justify-content-center  my-5  reveal justify-content-md-start ">
                        <div>
                          <HeaderDescription
                            leftBorder={false}
                            rightBorder={true}
                            topBorder={false}
                            bottomBorder={false}
                            buttonDisplay={false}
                            rulesDisplay={false}
                            rules={[]}
                            firstTitle={item.title}
                            secondTitle={item.title}
                            description={
                              item.content == "" || item.content == null
                                ? ""
                                : parse(item.content)
                            }
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            })}
          </div>

          {/* <div className="py-5 "></div> */}
        </div>
        <div
          className=" justify-content-start mt-3 ms-3 ms-sm-5 generalTitle"
          style={{ display: data.news.length == 0 ? "none" : "flex" }}
        >
          <GeneralTitle Title="News" />
        </div>
        <div
          style={{
            overflowX: "hidden",
            display: data.news.length == 0 ? "none" : "flex",
          }}
          className="mx-sm-5 m-0"
        >
          <div className="container p-0">
            {data.news.map((item, index) => {
              // console.log('ITEM ',item)
              if (index % 2 == 0) {
                return (
                  <div
                    className="my-5  m-0 row EachItem"
                    key={index}
                    style={{ backgroundImage: `url(${imageUrl + item.image})` }}
                  >
                    <Link to={"/singleEvent/" + item.id + "/" + item.slug}>
                      <div
                        className="phoneImage"
                        style={{
                          backgroundImage: `url(${imageUrl + item.image})`,
                        }}
                      ></div>
                      <div className="col-12 p-0 d-flex justify-content-center my-5 reveal justify-content-md-end ">
                        <div className="p-0">
                          <HeaderDescription
                            leftBorder={true}
                            rightBorder={false}
                            topBorder={false}
                            bottomBorder={false}
                            buttonDisplay={false}
                            rulesDisplay={false}
                            rules={[]}
                            firstTitle={item.title}
                            secondTitle={item.shortDescription}
                            description={
                              item.content == "" || item.content == null
                                ? ""
                                : parse(item.content)
                            }
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              } else {
                return (
                  <div
                    className="my-5  m-0 row EachItem"
                    style={{
                      backgroundPositionX: "right",
                      backgroundImage: `url(${imageUrl + item.image})`,
                    }}
                    key={index}
                  >
                    <Link to={"/singleEvent/" + item.id + "/" + item.title}>
                      <div
                        className="phoneImage"
                        style={{
                          backgroundImage: `url(${imageUrl + item.image})`,
                        }}
                      ></div>
                      <div className="col-12 p-0 d-flex justify-content-center reveal  my-5  justify-content-md-start ">
                        <div>
                          <HeaderDescription
                            leftBorder={false}
                            rightBorder={true}
                            topBorder={false}
                            bottomBorder={false}
                            buttonDisplay={false}
                            rulesDisplay={false}
                            rules={[]}
                            firstTitle={item.title}
                            secondTitle={item.shortDescription}
                            description={
                              item.content == "" || item.content == null
                                ? ""
                                : parse(item.content)
                            }
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            })}
          </div>

          {/* <div className="py-5 "></div> */}
        </div>
        <Footer />
      </>
    );
  } else {
    return <Loading />;
  }
};

export default SearchResults;
